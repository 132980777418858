import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Here’s Why you must hire ReactJS Developers from Metaqualt: "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Responsive UI"
                           Text="Create applications with built-in browser capabilities, allowing the product to adapt to the size of the device and deliver a great experience."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Easy Accessibility"
                           Text="You are a fraction of seconds away from connecting with us. We support you via call, chat, or mail. We are highly receptive to your needs. 
                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Faster Time To Market"
                            
                           Text="We respect your & our precious time. We leverage advanced tools and agile methodologies to catalyze your development process without losing its quality. 
                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On-Time Delivery"
                            Text="We understand your time is precious. Consequently, we keep our promises to you & deliver the product on time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Feature-Rich Solutions"
                           Text="
                           We deliver feature-rich solutions to meet your brand’s value and goals and that’s the best reason why you should hire us. 
                          "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Cost-Efficient"
                           Text="You get to hire a team that is sharp-focused on providing you with maximum ROI. 
                           Your money and your product’s quality matter to us! 
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;