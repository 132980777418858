import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';

// Service Image Icons
import roundImg from '../../assets/img/banner/sf-development.png';


import effectImg1 from '../../assets/img/about/effect-1.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about style3 gray-bg pt-120 pb-50 md-pt-30" style={{padding:"20px 0"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 md-mb-30">
                    <div className="rs-animation-image md-pt-0">
                            <div className="pattern-img">
                                <img 
                                    src={roundImg} 
                                    alt="" 
                                /> 
                            </div>
                            {/* <div className="middle-img hidden-md">
                                <img 
                                    className="dance3" 
                                    src={aboutImg} 
                                    alt="" 
                                />
                            </div> */}
                            
                        </div>
                    </div>
                    <div className="col-lg-6 pl-20 md-pl-14">
                        <div className="contact-wrap">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Speeding up Innovations in a Real World"
                                titleClass="title pb-25"
                                title="We Design, Develop, and Deliver the Best Experiences in the Global Market."
                                descClass="desc pb-27"
                                description="If you are looking for a remarkable web solution for your startup, enterprise, or a big organization, you have knocked on the right door! Meet the highly experienced technophiles teamed up to build your future."
                                secondDescClass="margin-0 pb-16"
                                secondDescription="We bring the latest technologies into practice to deliver a dynamic web or mobile app that’s more durable and smarter than your hardest-working employee. Yeah, it works even when you hit the sack! You get the masterpiece that attracts customers and generates sales. Our talented IT specialists are heedful of your values and develop a quality solution that you always feel proud to have. Want to accelerate your business growth like never before? Drop a note to us and we will get in touch ASAP."
                            />
                            {/* Section Title End */}
                            <div className="btn-part">
                                <Link className="readon learn-more" to="/contact">Continuous . . .</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
            </div>
        </div>
    );
}

export default About;