import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why choose PHP Developers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Reduced Development Cost"
                           
                            Text="Why hire in-house resources when you can close a cost-effective deal? Our PHP development service can lower your development cost by up to 40%. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Fill The Skill Gap"
                            
                            Text="Raise your website standards with our 5-year experience in developing complex and advanced PHP solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Ensure Operation Agility"
                           
                            Text="Strengthen your online effectiveness with our agile team that uses the latest technologies. You are one step away from getting powered up with a robust PHP development service. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Faster time to market"
                            
                           Text="We have an incredibly fast turnaround time; we use advanced technology, tools, and proven development practices to speed up the development process."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Choose from Large Pool"
                           
                            Text="You have knocked on the right door! We contain a large pool of talented PHP developers who are well-versed in the advanced tools in the web and mobile app development industry. 
                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Future Proof Websites"
                           
                           Text="Find PHP developers for hire with unprecedented knowledge & experience in developing modern solutions with the inclusion of technology such as AI, ML, Chatbots, AR/VR, etc."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;