import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Services"
                    titleClass="title testi-title text-white"
                    title="When it comes to Ecommerce App Development Services, we are the global leaders."
                    descClass="desc text-dark"
                    description="Reach out to our techs who specialize in building award-winning eCommerce apps to awe your target audience. Having the right plugins and tools, we create stunning solutions and maintain long-established customer loyalty. Choose our dedicated services to build an E-commerce store you will be proud of having. We make businesses grow. Your enterprise is a seed for our company’s growth.

                    "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Customer Satisfaction"
                            Text="
                            We note down your requirements and build a custom-made application that is human-friendly, secure, boosts sales, and is high in performance.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Eye-Catching User Experience"
                            Text="We begin with the end in mind! Our company’s success depends on how well we appeal to our end users with our interfaces. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Competitive Pricing "
                           Text="Even with the lowest pricing in our segment, we maintain our quality. We are industry experts in building great apps, but that doesn’t have to cost you an arm and a leg. 

                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Flexible Engagement Models"
                           Text="Our experts are well-trained to work in varied parameters. You are in control of our hiring models that offer noteworthy flexibility.

                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Integrity & Transparency"
                           Text="
                           Your needs and advice are our topmost priority while undertaking your app development project. We provide every detail of your project’s progress regularly. 
                           "
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Dedicated Teams"
                           Text="We have a talented treasure of developers that deliver future-ready e-commerce solutions. Let your products get global recognition with an awesomely built e-store. "
                        />
                    </div>
                    
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Our E-commerce Solutions Will Transform Your Business!"
                ctaTextClass="exp-text"
                ctaText="The team at our company specializes in developing e-commerce apps that are best-in-class."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;