import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/51.png';
import hoverIcon1 from '../../assets/img/service/style2/hover-img/51.png';
import mainIcon2 from '../../assets/img/service/style2/main-img/52.png';
import hoverIcon2 from '../../assets/img/service/style2/hover-img/52.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/53.png';
import hoverIcon3 from '../../assets/img/service/style2/hover-img/53.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-100 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text text-white"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Our optimal On-Demand Services 
                    "
                    descClass="desc text-dark"
                    description="Apart from our key solutions, there’s a huge list of on-demand solutions we can provide using the latest technology. Our techs empower your business by delivering trustworthy, viable, and future-ready on-demand apps. Whether it’s a car booking, E-wallet, Messenger, you name it, we can work together to make an app that illustrates your success story. 
                    "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            Title="Food & Grocery Delivery"
                            serviceURL="#"
                            Text="Satiate your appetite! With our Food and Grocery Apps, we make it hassle-free for your customers to order home-delivered food items. We use the latest tools to make apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            Title="E-wallet Application"
                            serviceURL="#"
                           Text="Our on-demand e-wallet app solutions with the greatest features and connectors are available to businesses of all sizes."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            Title="Instant Messaging App"
                            serviceURL="#"
                            Text="Take your chatting experience to the next level. We provide features like end-to-end encryption in our apps. We always strive to design and develop something you’ll enjoy. 

                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;