import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon29 from '../../assets/img/service/style2/main-img/29.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Services"
                    titleClass="title testi-title text-white"
                    title="What do our Leading Providers Of Social Media Apps bring to clients like you?
                    "
                    descClass="desc text-dark"
                    description="If you need a cost-effect solution from a leading social media app development company, nudge us! Your app's user experience ensures you stay in touch with us. Our technical experts see to it that your product is delivered on time, stands out from the crowd in the app store, gets the best support, and is secured and reliable."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon29}
                            hoverIcon={mainIcon29}
                            Title="Skilled Developers"
                            Text="We have a team of competent & experienced social app developers ready to assist you."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-Effective Solutions"
                           Text="You get a cost-effective solution. Also, we promise quality and scalability."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Timely Delivery"
                            Text="Within the project timeframes, our team completes the apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Confidentiality"
                           Text="We respect your ideas. By signing an NDA, we guarantee the confidentiality of the client's work."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Methodology"
                            Text="We follow the best techniques to ensure the timely delivery of your project."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Complete client satisfaction"
                            Text="As per your requirements, our team sets up to deliver secure, scalable, and user-friendly apps."
                        />
                    </div>
                    
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Build a Professional Social Media Application Today."
                ctaTextClass="exp-text"
                ctaText="We provide bespoke social networking app development services, including cutting-edge technologies."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;