import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hire Wordpress website Developers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Free Cost Estimation"
                           Text="Get a free quotation on your projects with guaranteed no hidden charges by sharing your project with our technology experts."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Diverse Industry experience"
                           Text="Hire a world-class Wordpress development company that has experience working in 15+ leading industries."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="3rd party integrations"
                            Text="WordPress plugin developers for hire that also provide you secure 3rd party integration services for enhanced capabilities. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Faster Customer Services"
                            Text="A 24x7 support team is available through e-mails, phone calls, and chat to help you with your problems."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Swift Development"
                           Text="Faster web development through modern development practices and tools and technology."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Offshore Development "
                           Text="Offshore development gives you cost-effective ways to meet your modern business needs."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;