import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Wordpress_developement  from '../../components/Common/Breadcumb/wordpress_developent';
import WordpressDevelopment from './wordpressmain';


// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';
const wrdpDevelopment = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}

                <Wordpress_developement 
                    pageTitle="CMS Development Services"
                    titleClass="page-title"
                    pageName="CMS Development Services "
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="CMS"
                />

                {/* breadcrumb-area-start */}
                {/* ServiceOneMain */}
                <WordpressDevelopment />
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}
export default wrdpDevelopment;