import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-80 pb-80">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text gold-color"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Android App Development Services rock the smart screens."
                        descClass="desc mb-25"
                        description="If you need an Android app development service to showcase your ideas, hire our skilled developers. We come up with innovative apps that flawlessly run over Android devices — Irrespective of your device brand, android version, and screen size. We build effective solutions that materialize your visions."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Android App Development & Design"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Wearable App Development"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Enterprise Mobility Management"
                                 />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Ecommerce & AI App Development"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="On-Demand App Development"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="QA & Testing"
                                 />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default OurServices;