import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain16 from '../../assets/img/service/style2/main-img/16.png';
import imgHover16 from '../../assets/img/service/style2/hover-img/16.png';
import imgMain17 from '../../assets/img/service/style2/main-img/17.png';
import imgHover17 from '../../assets/img/service/style2/hover-img/17.png';
import imgMain21 from '../../assets/img/service/style2/main-img/21.png';
import imgHover21 from '../../assets/img/service/style2/hover-img/21.png';
import imgMain23 from '../../assets/img/service/style2/main-img/23.png';
import imgHover23 from '../../assets/img/service/style2/hover-img/23.png';
import imgMain22 from '../../assets/img/service/style2/main-img/22.png';
import imgHover22 from '../../assets/img/service/style2/hover-img/22.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.png';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const AngularJsDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
             <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="We Harness the Features of  Vue.JS to Develop Authentic Front-End Apps"
                                descClass="desc pb-5"
                                description="
                                Watch your dream of the best interactive UI and outstanding UX come true as our Veu.JS team develops an unsurpassed product for you. Sticking to your goals and market demands we leverage the compelling features of VueJS like JavaScript libraries, component-composition caching, and server-side declarative rendering."
                                secondDescClass="desc pb-16"
                                secondDescription="Our specialty lies in developing single-page web apps compared to Native, hybrid-Phonegap, or Cordova apps. Hire our VueJS development service, which is worth your time and budget, giving you a feature-rich app. 
                                "
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="We Provide"
                        titleClass="title pb-10"
                        title="Crank up your online influence with a next-gen VueJS Development"
                        descClass="desc"
                        description="Be one step ahead of your rivals by building a cost-effective yet innovative and trendiest app using our VueJS web development services."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain16}
                                    HoverImg={imgHover16}
                                    Title="Custom App Development
                                    "
                                    Text="Your comprehensive Vue.JS web development from start to finish is our responsibility.
                                    From consultation, planning, and development to maintenance, we sincerely do it all to give you a full-fledged solution that suits your brand goals. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain17}
                                    HoverImg={imgHover17}
                                    Title="One Page Application Development
                                    "
                                    Text="Make the most of highly appreciated and feature-rich single-page apps made using the globally celebrated technology. We provide the competitive, compelling, and high-performing one-page apps at a reasonable rate. 

x                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain22}
                                    HoverImg={imgHover22}
                                    Title="3rd Party Integration
                                    "
                                    Text="Your business wouldn’t ask for more when you appoint our skillful developers. We adroitly integrated third-party APIs that enhance the experience of your Vue.JS web apps, bringing more scalability and flexibility to the processes. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain21}
                                    HoverImg={imgHover21}
                                    Title="Enterprise Web Applications
                                    "
                                    Text="Your SME or large-scale business is on the right track of development with our Vue.JS development company. All you get is a user-friendly, scalable, well-armed, and hale and hearty app."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain23}
                                    HoverImg={imgHover23}
                                    Title="Real-time App Development
                                    "
                                    Text="Our development approach is user-centric and our products are performance-centric. Pick the leaders of web app developers who leverage the power of the Vue.JS framework to give you a real-time web app that responds to user inputs in the blink of an eye or less! 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center mt-10 mb-10"
                                     titleClass="title pb-10 fz-36"
                                    title="Our Professional Experts Are Inclined Towards Developing Robust Front-end With VueJS"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why rely on our expertise for Development?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="With our years of experience in user-centric app development using Vue.Js, the solution you get is the unrivaled window to showcase your talent online."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="Our entire development process, from ideation to delivery, revolves around your goals and needs. Your website’s maintenance is in the hands of skilled and proactive developers." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon24}
                            Title="Year-long Experience" 
                            Text="Developing a feature-rich app is a piece of cake for our experienced team, who have upgraded their skills promptly. So, what you get is a sheer 
                            hassle-free and authoritative application" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon25}
                            Title="Result-Drive" 
                            Text="We don’t just provide glittering apps. We give a customized product that suits your industry’s needs. What you get is glittering and golden, appealing and profitable." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
            {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Embrace a drastic transformation! Hire sincere Vue.JS Developers."
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AngularJsDevelopment;