import React from 'react';
import { Link } from 'react-router-dom';
import HeaderStyleThree from '../components/Layout/Header/HeaderStyleThree';
import Footer from '../components/Layout/Footer';
import SearchModal from '../components/Layout/Header/SearchModal';
import ScrollToTop from '../components/Common/ScrollTop';
import Career_info from '../components/Common/Breadcumb/career';
import CareersMain from '../components/Careers';

// Breadcrumbs Background Image
import bannerbg from '../assets/img/banner/banner-6.jpg';

const CareersMainPage = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='pages'
                activeMenu='/careers'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Career_info 
                    pageTitle="Careers"
                    titleClass="page-title"
                    pageName="Careers"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Careers"
                />
                {/* breadcrumb-area-start */}

                {/* Faq */}
                <CareersMain />
                {/* Faq */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </div>
            <Footer />
            <SearchModal />
        </React.Fragment>

    );
}


export default CareersMainPage;