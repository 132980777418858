import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';

import shapeImg from'../../assets/img/process/circle.png';
import valueBg from'../../assets/img/banner/value-bg.png';

const Mission = () => {

    return (
        <div className="gray-bg" style={{
            backgroundImage: 'url('+valueBg+')',
            backgroundSize: "cover",
            backgroundPosition:"left bottom"
          }}>
            <div className="rs-process modify1 pt-80 pb-80">
                <div className="shape-animation hidden-sm">
                    <div className="shape-process">
                        <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-text-center pr-40 md-pr-14 md-pb-80">
                            <SectionTitle
                                sectionClass="sec-title2 mb-30 md-pl-30 md-pr-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="ABOUT US"
                                titleClass="title mb-5"
                                title="Tenets of our Team"
                                descClass="desc pb-2"
                                description="We live by our values. Our values are not mere beliefs but promises. We believe in constant learning, delivering intrinsic value, and being who you say you are. These are the bedrock of our organization. Our customer feedback signifies who we are, so we put them, their values, and their needs first and foremost.  
                                "
                            />
                            <div className="btn-part mt-40">
                                <Link className="readon started" to="/contact">Contact Us</Link>
                            </div>
                        </div>
                        <div className="col-lg-8 sm-pl-40 sm-pr-20">
                            <div className="row">
                                <div className="col-md-6 mb-70 ">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c1"
                                        prefixClass="number-area"
                                        prefix="1."
                                        titleClass="title"
                                        Title="Our Vision"
                                        descClass="number-txt"
                                        Description="Gee, Our vision aligns with your business requirements! We strive to serve global clients with benchmark quality, swift marketing, and competitive cost advantage. All in all, we brainstorm to come up with groundbreaking technology in web development and design sectors.  
                                        "
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c2"
                                        prefixClass="number-area green-bg"
                                        prefix="2."
                                        titleClass="title"
                                        Title="Mission"
                                        descClass="number-txt"
                                        Description="We are aware that people in action appeal more to you than people bragging about their massive plans. Our team leaves no stone unturned to make our vision tangible. We are agile professionals who provide innovative & reliable products designed to meet your business needs. "
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c4"
                                        prefixClass="number-area plum-bg"
                                        prefix="3."
                                        titleClass="title"
                                        Title="Quality"
                                        descClass="number-txt"
                                        Description="We don’t work for the sake of working. The result - every customer gets a “unique” solution that suits their needs. We are committed to operating responsibly, emphasizing organizational performance and teamwork. We provide out-of-the-box solutions to clients like you. "
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number bg-light-c3"
                                        prefixClass="number-area pink-bg"
                                        prefix="4."
                                        titleClass="title"
                                        Title="Commitment"
                                        descClass="number-txt"
                                        Description="
                                        The quality of services you get reflects our passion for work. We leave a satisfactory grin on your face with our cutting-edge apps and software. Be it an Android, iOS app, or a website, our smart alchemists leverage the power of frameworks to create a snazzy solution. 
                                         
                                         "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;