import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/e-commerce-app.png';

const AboutSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 style3 pt-80 pb-80 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="E-commerce Application that actually scales!"
                                    descClass="desc text-white"
                                    description="We convert visitors into customers! Watch your brand expand with our latest themes, features, and overall seamless user experience. Our cutting-edge technology and striking designs help you gain new and retain existing customers. Vendors can sell a wide range of products in a global marketplace using our e-commerce mobile apps. Our skilled crew helps you make an app that is a buyers’ paradise."
                                />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">E-catalogs</li>
                                        <li className="text-white">Products Gallery</li>
                                        <li className="text-white">Advanced Product Search</li>
                                        <li className="text-white">Multiple Purchase Options</li>
                                        <li className="text-white">Add to Favorites</li>
                                        <li className="text-white">Ad section</li>
                                        <li className="text-white">Multi-Currency Support</li> 
                                        <li className="text-white">User-friendly app navigation</li>
                                        <li className="text-white">Reports & analytics</li>
                                    </ul>
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Social Media Login</li>
                                        <li className="text-white">Track Inventory</li>
                                        <li className="text-white">Order Tracking & Management</li>
                                        <li className="text-white">Coupons and Promo Codes</li>
                                        <li className="text-white">Multiple Payment Options Support</li>
                                        <li className="text-white">Real-Time Order Monitoring</li>
                                        <li className="text-white">Multi-currency and Multilingual support</li> 
                                        <li className="text-white">Complete security includes transactions and payment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider