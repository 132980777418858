import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Codeigniter/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon4 from '../../assets/img/service/style2/main-img/4.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import imageServices1 from '../../assets/img/service/icon-1.webp'; 
import imageServices2 from '../../assets/img/service/icon-2.webp'; 
import imageServices3 from '../../assets/img/service/icon-3.webp'; 
import imageServices4 from '../../assets/img/service/icon-4.webp'; 
import imageServices5 from '../../assets/img/service/icon-5.webp'; 
import imageServices6 from '../../assets/img/service/icon-6.webp'; 
import imageServices7 from '../../assets/img/service/icon-7.webp'; 
import imageServices8 from '../../assets/img/service/icon-8.webp'; 
import imageServices9 from '../../assets/img/service/icon-9.webp'; 
import imageServices10 from '../../assets/img/service/icon-10.webp'; 


const ciDevelopmentMain = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Our Expertise"
                                titleClass="title pb-25"
                                title="Creating CodeIgniter solutions that are successful, impressive, and effective."
                                descClass="desc pb-5"
                                description="We take everything as it comes. The functionality of the Codeigniter web application made us curious to master the framework. We are a renowned Codeigniter development service that crowns your web presence with a fast-paced solution."                              secondDescClass="desc pb-16"
                                secondDescription="If you want a dynamic site built using PHP, Codeigniter is your solution. With its vast options, you don’t have to write the same code repeatedly. With this toolkit handled by our talented developers, turning your ideas into a full-fledged website is quick and exciting. 
                                "
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
            
            {/* rs-servicess-area-start */}
            <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Codeigniter Development Services"
                        titleClass="title"
                        title="Make your brand viral by building a trendy website using Codeigniter Development. 
                        "
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={imageServices4}
                            Title="Customized E-Commerce Development"
                           
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices6}
                            Title="PHP CodeIgniter Web Application Development" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices2}
                            Title="Develop Web Portals with PHP CodeIgniter" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices8}
                            Title="CodeIgniter Social Networking Solutions" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices3}
                            Title="Easy configuration and simplicity" 
                           
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices9}
                            Title="CodeIgniter Maintenance & Support" 
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices6}
                            Title="Back-end for Mobile Apps Development"
                            
                        />
                        <SingleServiceFour
                            serviceIcon={imageServices5}
                            Title="Integration with SugarCRM Development"
                            
                        />
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */} 

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="
                        Why Hire Us for Development?
                        "
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Year-long Expertise"
                            Text="You get to work with the leading Codeigniter experts having unrivaled experience in delivering domain-specific Web solutions.  
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="Rest assured as you, your ideas, and your brand voice is valued. We create apps with the power of CodeIgniter framework that revolves around your core needs. Long answer short, We code to meet your core needs. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            Title="Futuristic Development" 
                            Text="Give us a high-five! Your drop-dead gorgeous app is going to emerge and dominate the rivals in your niche. Moreover, we make sure it sustains its influence! 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Dedicated Team" 
                            Text="Your progress is a result of our dedicated team's work. We are equipped with the right tools & brains to give you what we promise.
                            " 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
              {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Skilled CodeIgniter Developers"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible web apps. With our  CodeIgniter masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default ciDevelopmentMain;