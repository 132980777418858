import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-80 pb-80">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text gold-color"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Cross-Platform App Development Services"
                        descClass="desc mb-25"
                        description="The improved capabilities of cross-platform apps have made it widely popular. With modern technology, you can provide an unparalleled app experience across applications."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Native Android App Development Services"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="React Native App Development Services"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Ionic Android Development Services"
                                 />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Cross-Platform Mobile App Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Agile Cross-platform Development"
                                 />
                            </div>
                            <div className="col-md-12 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Flutter App Development Services"
                                 />
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
            </div>
        </div>
    );
}

export default OurServices;