import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import E_commerce_development from '../../components/Common/Breadcumb/e_commerce_development';
import ECommerceMain from './eCommerceMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';


const eCommerceMain = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <E_commerce_development
                    pageTitle="eCommerce Development Services"
                    titleClass="page-title"
                    pageName="eCommerce Development Services "
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="eCommerce"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <ECommerceMain />
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default eCommerceMain;