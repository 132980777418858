import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg1 from '../../assets/img/service/style2/main-img/55.png';
import serviceImg2 from '../../assets/img/service/style2/main-img/S2.png';
import serviceImg3 from '../../assets/img/service/style2/main-img/S3.png';
import serviceImg4 from '../../assets/img/service/style2/main-img/S4.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-60 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Business Industry"
                    titleClass="title new-title"
                    title="Revolutionize your business with trendy apps"
                    descClass="desc"
                    description="By utilizing the most updated tools and technologies, our team develops next-generation Business apps. The purpose of our business applications is to refine and optimize complex business processes. Clients always receive the best B2C/B2B software solutions that meet their specific needs."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg3}
                            Title="Business Software Development"  
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-pink"
                            serviceImg={serviceImg2}
                            Title="Business Application Integration"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleServiceSix
                            itemClass="services-item bg-light-green"
                            serviceImg={serviceImg1}
                            Title="Digital Transformation Service" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleServiceSix
                            itemClass="services-item bg-light-red"
                            serviceImg={serviceImg4}
                            Title="Enterprise Mobility Solutions"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

