
import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import AboutMain from './AboutMain';


// Breadcrumbs Background Image

import bannerbg from '../../assets/img/banner/banner-6.jpg';
const About = () => {
    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='about'
                 activeMenu='/about'
            />
            
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="Who We Are"
                    titleClass="page-title"
                    pageName="About"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="About Us"
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <AboutMain />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>

            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default About;