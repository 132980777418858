import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Ux_ui_designer from '../../components/Common/Breadcumb/ux_ui_designer';
import WebDesign from './webDesignMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';


const HTML5CSS3 = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Ux_ui_designer
                    pageTitle="UX/UI Design Services"
                    titleClass="page-title"
                    pageName="UX/UI Design Services"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="UX/UI Design"
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                <WebDesign />
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default HTML5CSS3;