import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/travel.png';



const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0, 
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
             <div className="bg18 style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Our Simple Yet Efficient Travel Mobile App Development Solutions"
                                    descClass="desc text-white"
                                    description="We help your end-users simplify the tour planning/booking process, thus helping you retain existing and attract new customers. Our modern technologies boost your app’s user experience and help you gain a good reputation with travelers worldwide."
                                />
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Currency converter</li>
                                        <li className="text-white">Real-time push notifications</li>
                                        <li className="text-white">Secure payment gateway integration</li>
                                        <li className="text-white">GPS based location services</li>
                                        <li className="text-white">Virtual tour guide</li>
                                        <li className="text-white">Reviews And Recommendations</li>
                                    </ul>
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Trip planning</li>
                                        <li className="text-white">Travel diary</li>
                                        <li className="text-white">Tour booking</li>
                                        <li className="text-white">Travel assistant</li>
                                        <li className="text-white">Travel photography</li>
                                        <li className="text-white">Social Media Integration</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider