import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Full_Stack_developer from '../../components/Common/Breadcumb/full_stack_developer';

import HireFullStackDevelopers from './hirefullstackDevelopersMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const HireFullStackDevelopersPage = () => {

    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='Hire PHP Developers'
                 activeMenu='/Hire Developers'
            />
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                <Full_Stack_developer
                    pageTitle="Hire Dedicated Full Stack Developers"
                    titleClass="page-title"
                    pageName="Hire Dedicated Full Stack Developers"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Hire Full Stack Developers"
                />
                {/* breadcrumb-area-start */}
        
                {/* About Main */}
                <HireFullStackDevelopers />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default HireFullStackDevelopersPage;