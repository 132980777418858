import React from 'react';
import SectionTitle from '../Common/SectionTitle';

// WhyChooseUs Images
import iconOne from '../../assets/img/choose/icons/style2/1.png';
import iconTwo from '../../assets/img/choose/icons/style2/2.png';
import iconThree from '../../assets/img/choose/icons/style2/3.png';



const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        
         <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
         <SectionTitle
             sectionClass="sec-title mb-44"
             subtitleClass="sub-text new-text white-color"
             subtitle="Let's Talk"
             titleClass="title white-color"
             title="Speak With Expert"
             effectClass="heading-line"
         />
                     <div className="services-wrap mb-30">
                         <div className="services-icon">
                             <img
                                 src={iconOne}
                                 alt="Choose Icon"
                             />
                         </div>
                         <div className="services-text">
                             <h3 className="title white-color">Email</h3>
                             <p className="services-txt white-color"><a href="mailto:info@metaqualt.com" className='text-white'>info@metaqualt.com</a></p>
                         </div>
                     </div>
                     <div className="services-wrap mb-30">
                         <div className="services-icon">
                             <img
                                 src={iconTwo}
                                 alt="Choose Icon"
                             />
                         </div>
                         <div className="services-text">
                             <h3 className="title white-color">Call Us</h3>
                             <p className="services-txt white-color">+91 7096069000</p>
                         </div>
                     </div>
                     <div className="services-wrap">
                         <div className="services-icon">
                             <img
                                 src={iconThree}
                                 alt="Choose Icon"
                             />
                         </div>
                         <div className="services-text">
                             <h3 className="title white-color">Office Address</h3>
                             <p className="services-txt white-color">Office: 417,418 Satyamev Eminence, Science City Rd Sola, Ahmedabad, Gujarat 380060</p>
                         </div>
                     </div>
     </div>
    );

}


export default ContactInfo;