import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/realetate.png';



const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0, 
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
             <div className="bg18 style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Our future-proof Real Estate Application Solutions for notable returns."
                                    descClass="desc text-white"
                                    description="We strive to help you achieve your business goals. Our talented squad is disciplined to deliver top-notch real estate apps. Our salient features include:"
                                />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Interest Rate Calculators</li>
                                        <li className="text-white">AI-enabled Chatbots</li>
                                        <li className="text-white">Users, Agents, Sellers Profile</li>
                                        <li className="text-white">Tracking License and Status</li>
                                        <li className="text-white">Multilingual & Multi-currency</li>
                                        <li className="text-white">Videos and Virtual Tours</li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Property Listings</li>
                                        <li className="text-white">Push Notifications</li>
                                        <li className="text-white">Calendars</li>
                                        <li className="text-white">Currency Converters</li>
                                        <li className="text-white">Property Estimates</li>
                                        <li className="text-white">GPS Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider