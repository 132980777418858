import React from 'react';
import OurServices from './weProvide';
import WeServes from './it-services';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';
import WhyChoose from './whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import CTA from '../../components/Common/CTA';
import SingleServiceFour from '../../components/Service/SingleServiceFour';

// Working Process Images
import processImagePR1 from '../../assets/img/process/PR1.png';
import processImagePR2 from '../../assets/img/process/PR2.png';
import processImagePR3 from '../../assets/img/process/PR3.png';
import processImagePR4 from '../../assets/img/process/PR4.png';

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
// Service Icons
import hirewordpress from '../../assets/img/about/hirewordpress.png';
import bannerImg from '../../assets/img/about/dedicated2.png';
import imgservice6 from '../../assets/img/technology/style2/wordpress.png';

const HireWordpressDeveloperMain = () => {
    return (
        <React.Fragment>
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about pt-150 pb-20 md-pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                             <div className="text-center relative">
                                 <img className="main" src={bannerImg} alt=""/>
                            </div>
                            <div className="banner-img absolute" style={{top: 25, left:0}}>
                                <img
                                    className="layer-img dance"
                                    src={imgservice6}
                                    alt=""
                                />                    
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pb-70 pt-70 md-pt-10 md-pb-20"
                                subtitleClass="sub-text gold-color"
                                subtitle="Hiring"
                                titleClass="title pb-25"
                                title="Hire Wordpress Developers"
                                descClass="desc mb-25"
                                description="Provide your business with specialized WordPress development services by hiring dedicated Wordpress developers. Hire WordPress developers online with our customer-centric solutions."
                                secondDescClass="mb-10 pb-16"
                                secondDescription="Today, WordPress powers millions of products and is one of the most dynamic platforms. This platform can be used to develop a variety of features, such as CMS, ecommerce, web application, and website development. Using WordPress, we can develop plugins, themes, websites, and web applications. Develop visually stunning web interfaces with Wordpress theme developers."
                            />                           
                        </div>
                    </div>
                </div>
            </div>
            {/* about-area-start */} 

            {/* counter area start */}
            {/* <CounterHomeFive /> */}
            {/* counter area end */}

            {/* about-area-start */}
            <div className="style3 pt-100 pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle=" "
                                    titleClass="title pb-10"
                                    title="How Hiring WordPress Developers Will Help to Build Feature Rich Enterprise-Class Websites?"
                                    descClass="desc"
                                    description="Get the most benefit out of WordPress with the help of WordPress Programmers. Wordpress offers some outstanding solutions that can help you grow your business."
                                />
                                </div>
                                <div className='col-lg-4 col-md-12'>
                                    <ul className="check-square">
                                        <li> Ease of Use</li>
                                        <li> Easy to manage</li>
                                        <li> Scalable</li>
                                        <li> SEO-friendly</li>
                                        
                                        <li> Built-in CMS</li>
                                    </ul>
                                    
                                </div>
                                <div className='col-lg-8 col-md-12'>
                                    <ul className="check-square">
                                        <li> No HTML editing or FTP Software required</li>
                                        <li> Highly functional with millions of plugins</li>
                                        <li> High-functionality</li>
                                        <li> Multi-user capabilities</li>
                                        <li> Customizable designs</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center relative">
                                    <img className="main" src={hirewordpress} alt=""/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* about-area-end */}

            {/* about-area-start */}
			{/* <WeServes /> */}
			{/* about-area-end */}

            {/* Project-start */}
			<OurServices />
			{/* Project-end */}     

            {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

            {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			{/* <div className="mb-50">
                <div className="container rs-call-us relative pt-80 pb-80">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rs-contact-box text-center">
                                <CTA
                                    ctaSectionClass="rs-cta style1 text-center"
                                    ctaTitleClass="epx-title"
                                    ctaTitle="Share Your Want List. We’ll Get It Done From There."
                                    ctaTextClass="exp-text"
                                    ctaText="Really, we mean it. Jot down the complete details you want to include in your project. Tech-savvy we are here to share the expertise you required yesterday and deliver outstanding results."
                                    ctaButtonClass="readon learn-more"
                                    ctaButtonLink="./careers"
                                    ctaButtonText="Share Your Idea"
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div> */}
			{/* newsletter-area-end */}

            {/* working-process-area-start */}
             <div className="rs-process style2 pt-40 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Hiring Process"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="We have made our hiring process highly transparent. Here is how easy it is to find the best-dedicated development team and get started on your project."
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR1}
                                titleClass="title"
                                Title="Share your project"
                                Text="Share a detailed project copy with us to give us an understanding of your project needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR2}
                                titleClass="title"
                                Title="Interview the resources"
                                Text="Once we have the details, we would share with you the best available resources that fit your needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR3}
                                titleClass="title"
                                Title="Select the resources"
                                Text="Select the resources and knowledge about our pricing models, time of development, and finalize."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR4}
                                titleClass="title"
                                Title="Sign a contract"
                                Text="Last, sign an NDA with our company, and we get started on the development of the project."
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			{/* <Brand /> */}
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default HireWordpressDeveloperMain;