import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hire Cross-Platform App Developers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Modern Technology"
                           Text="Use of dependable technologies to build amazing app interfaces for cross-platform applications."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Quality Assurance"
                           Text="Quality assurance through our testing services for ensuring top-notch experience on your app."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="User Interface"
                            Text="We offer you the best front-end development services, which make the user interface top-notch."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Expertise on Multiple Platforms"
                            Text="We provide you services to develop applications for multiple platforms."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Daily Reports"
                           Text="We provide you daily reports on the work progress through emails, calls and chat."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Mobile App Development Skill Set"
                           Text="Years of experience in building amazing mobile applications for different platforms."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;