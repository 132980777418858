import React from 'react';
import WeServes from './it-services';
import OurServices from './weProvide';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';
import WhyChoose from './whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import CTA from '../../components/Common/CTA';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
// Working Process Images
import processImagePR1 from '../../assets/img/process/PR1.png';
import processImagePR2 from '../../assets/img/process/PR2.png';
import processImagePR3 from '../../assets/img/process/PR3.png';
import processImagePR4 from '../../assets/img/process/PR4.png';

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
// Service Icons
import normalLogo1 from '../../assets/img/technology/style2/17.png';
import normalLogo2 from '../../assets/img/technology/style2/13.png';
import normalLogo3 from '../../assets/img/technology/style2/172.png';
import normalLogo23 from '../../assets/img/technology/style2/23.png';
import normalLogo24 from '../../assets/img/technology/style2/24.png';
import normalLogo25 from '../../assets/img/technology/style2/25.png';
import normalLogo26 from '../../assets/img/technology/style2/26.png';
import normalLogo27 from '../../assets/img/technology/style2/27.png';
import normalLogo28 from '../../assets/img/technology/style2/28.png';
import normalLogo29 from '../../assets/img/technology/style2/29.png';
import normalLogo30 from '../../assets/img/technology/style2/30.png';
import normalLogo31 from '../../assets/img/technology/style2/31.png';
import normalLogo32 from '../../assets/img/technology/style2/32.png';
import normalLogo33 from '../../assets/img/technology/style2/33.png';
import normalLogo6 from '../../assets/img/technology/style2/6.png';
import normalLogo19 from '../../assets/img/technology/style2/19.png';
import bannerImg from '../../assets/img/about/dedicated.png';
import shapeImg from '../../assets/img/banner/style2/bnr-ly1.png';

const HirePHPDevelopersPage = () => {

    return (
        <React.Fragment>
           
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about style3 pt-150 pb-0 md-pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                             <div className="text-center relative">
                                 <img className="main" src={bannerImg} alt=""/>
                            </div>
                            <div className="banner-img absolute" style={{top: 25, left:0}}>
                                <img
                                    className="layer-img"
                                    src={shapeImg}
                                    alt=""
                                />                    
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pb-70 pt-70 md-pt-10 md-pb-20"
                                subtitleClass="sub-text gold-color"
                                subtitle="We dream big for you"
                                titleClass="title pb-25"
                                title="We are committed to empowering you."
                                descClass="desc mb-25"
                                description="We help you do amazing things with our dynamic web and mobile apps. Your exquisite and punchy apps result from our team’s impeccable love for developing web solutions. 
                                You can glimpse our love to develop by exploring our work behind the scenes.
                                "
                                secondDescClass="mb-10 pb-16"
                                secondDescription="For a cost-effective project that yields the best software, hire our top-notch programmers. You can choose the pace of our work, meaning, you can hire us on a full-time, part-time, or hourly basis. We set you full-stack web or mobile app development in action and soon your ideas materialize. Our team adheres to the trending technologies and the solution you get is reliable, outstanding, & winsome."
                            />                           
                        </div>
                    </div>
                </div>
            </div>
            {/* about-area-start */} 

            {/* counter area start */}
            {/* <CounterHomeFive /> */}
            {/* counter area end */}

            {/* about-area-start */}
			<WeServes />
			{/* about-area-end */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Hire Dedicated Team"
                        titleClass="title pb-10"
                        title="Employ Dedicated Developers to work in line with your time zone, deadline, and milestone
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow pb-20">
                                    <SectionTitle
                                        sectionClass="sec-title4 text-left mb-30"
                                        titleClass="title pb-0"
                                        title="Hire Mobile App Developers"
                                        descClass="desc"
                                        description="Watch your business scale with our tailor-made app that runs on multiple platforms. We wield our knowledge of trending technologies to develop innovative apps." 
                                    />
                                    <div className="rs-technology style2 pt-30 pb-30">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo1} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo1} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Android App</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo2} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo2} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">iPhone App</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo3} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo3} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Cross Platform</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow pb-20">
                                    <SectionTitle
                                        sectionClass="sec-title4 text-left mb-30"
                                        titleClass="title pb-0"
                                        title="Hire JMVVM Framework"
                                        descClass="desc"
                                        description="We handle projects of any complexity level. With an experience in JMVVM Framework development, the app development process is like a piece of cake for us."  
                                    />
                                    <div className="rs-technology style2 pt-30 pb-30">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo29} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo29} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Angular.js</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo30} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo30} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">React.js</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo31} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo31} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Node.js</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow pb-20">
                                    <SectionTitle
                                        sectionClass="sec-title4 text-left mb-30"
                                        titleClass="title pb-0"
                                        title="Hire Dedicated Web Developers"
                                        descClass="desc"
                                        description="Meet top-class coders at MetaQualt to develop incredible modern solutions, offering rich user experience and solid performance."  
                                    />
                                    <div className="rs-technology style2 pt-30 pb-30">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo23} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo23} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">CodeIgniter</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo24} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo24} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Laravel</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo25} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo25} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">PHP</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow pb-20">
                                    <SectionTitle
                                        sectionClass="sec-title4 text-left mb-30"
                                        titleClass="title pb-0"
                                        title="Hire UI/UX Developers"
                                        descClass="desc"
                                        description="Making the most of your needs and requirement we design masterpieces that are attractive and interactive. Hire our UI/UX Developers and let your dreams take shape."  
                                    />
                                    <div className="rs-technology style2 pt-30 pb-30">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo27} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo27} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Html5</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo26} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo26} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">CSS3</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo28} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo28} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Sass</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mb-30 ">
                                <div className="box-shadow pb-20">
                                    <SectionTitle
                                        sectionClass="sec-title4 text-left mb-30"
                                        titleClass="title pb-0"
                                        title="Hire eCommerce & CMS Developers"
                                        descClass="desc"
                                        description="Hire our e-commerce and CMS developers for a reason. For you get a result-driven, unrivaled, and trend-setting product to rely on."  
                                    />
                                    <div className="rs-technology style2 pt-30 pb-30">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo6} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo6} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">WordPress</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo19} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo19} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Magento</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo32} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo32} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">Shopify</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 pb-30">
                                                <div className="technology-wrap">
                                                    <div className="rs-grid-figure">
                                                        <div className="logo-img">
                                                            <a href="#">
                                                                <img className="hover-img" src={normalLogo33} alt="hover-image" />
                                                                <img className="main-img" src={normalLogo33} alt="main-image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="logo-title">
                                                        <h4 className="title">WooCommerce </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-end */}

            {/* Project-start */}
			<OurServices />
			{/* Project-end */}       
            
             {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-60">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			{/* <div className="mb-50">
                <div className="container rs-call-us relative pt-80 pb-80">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rs-contact-box text-center">
                                <CTA
                                    ctaSectionClass="rs-cta style1 text-center"
                                    ctaTitleClass="epx-title"
                                    ctaTitle="Share Your Want List. We’ll Get It Done From There."
                                    ctaTextClass="exp-text"
                                    ctaText="Really, we mean it. Jot down the complete details you want to include in your project. Tech-savvy we are here to share the expertise you required yesterday and deliver outstanding results."
                                    ctaButtonClass="readon learn-more"
                                    ctaButtonLink="./careers"
                                    ctaButtonText="Share Your Idea"
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div> */}
			{/* newsletter-area-end */}

            {/* working-process-area-start */}
             <div className="rs-process style2 pt-40 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Our Hiring Process"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="With MetaQualt, it’s just a piece of cake for you to express your ideas and get the project started to convert them into a valuable product. 
                        Share your project - interview the resources - select the resources - sign a contract 
                        "
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR1}
                                titleClass="title"
                                Title="Share your project"
                                Text="Share a detailed project copy with us to give us an understanding of your project needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR2}
                                titleClass="title"
                                Title="Interview the resources"
                                Text="Once we have the details, we would share with you the best available resources that fit your needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR3}
                                titleClass="title"
                                Title="Select the resources"
                                Text="Select the resources and knowledge about our pricing models, time of development, and finalize."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR4}
                                titleClass="title"
                                Title="Sign a contract"
                                Text="Last, sign an NDA with our company, and we get started on the development of the project."
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			{/* <Brand /> */}
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default HirePHPDevelopersPage;