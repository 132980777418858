import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon90 from '../../assets/img/service/style2/main-img/90.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Choosing our apt services for Real Estate Business Apps"
                    descClass="desc text-dark"
                    description="We provide excellence to clients like you. Our real estate app can increase your revenue to a remarkable extent. We are well-versed in the app industry and satisfied customers vouch for our name when anyone needs an application for their enterprise. Manage your business effectively and achieve stunning results with our real estate development solutions."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="One-Stop Destination"
                            serviceURL="#"
                            Text="Your business, large or small, can team up with us to scale itself up with outstanding applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Expert professionals"
                            serviceURL="#"
                           Text="We have a team of qualified specialists on hand to ensure you get the finest solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            Text="With the agile methodology, we pace up our processes and provide the best solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon90}
                            hoverIcon={mainIcon90}
                            Title="Round-The-Clock Support"
                           Text="We have got your back. Our 24/7 assistance ensures your app is up-to-date all the time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-effectiveness"
                            Text="Your every dime is worth it. You get a high-quality solution at an affordable price."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Quick Delivery"
                            Text="We respect your time and budget. Our company delivers a cost-effective app on time."
                        />
                    </div>
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Get A Customized Real Estate App Quickly."
                ctaTextClass="exp-text"
                ctaText="Grow your real estate business with our top-class mobility solutions. Start your project now!"
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;