import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/style9/ss5.png';
import serviceImg13 from '../../assets/img/service/style9/ss6.png';
import serviceImg11 from '../../assets/img/service/style9/ss7.png';
import serviceImg12 from '../../assets/img/service/style9/ss8.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Fintech Industry"
                    titleClass="title new-title"
                    title="Let your digital presence have an impact on the global economy with our Robust Fintech App
                    "
                    descClass="desc"
                    description="If you want to meet the industry benchmarks, our fintech team will build suitable apps for you. We have got the right stuff to help you financially bloom, using our cutting-edge apps. Our skills, creativity, and experience in developing apps for various finance sectors have won us plenty of customers. Whether it is a fintech start-up, a traditional non-financial, or a financial firm, we never fail to give a superior customer experience. Using the latest trends to build great fintech apps, we offer quality services that you and your customers will fall for. "
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Mobile Payments (mPOS)"
                            
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Personal FinTech Software"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Banking Mobile Applications" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg12}
                            Title="Crowdfunding Portal Development"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

