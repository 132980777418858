import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon13 from '../../assets/img/service/style2/main-img/13.png';
import mainIcon25 from '../../assets/img/service/style2/main-img/25.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hire PHP Programmer from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Technical Expertise"
                            Text="Laravel Programmers for hire that offer you years of experience and expertise in building high-performing applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Experienced Team"
                            Text="Hire developers with an average experience of five years and industry experience to build you amazing solutions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon13}
                            hoverIcon={mainIcon13}
                            Title="Maintenance Services"
                            Text="Maintenance services that make sure the web application is always of top-notch quality and makes you unstoppable.."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon25}
                            hoverIcon={mainIcon25}
                            Title="Direct Point of Contact"
                           Text="Direct point of contact that allows you to provide the necessary feedback needed to make the product more & more as you wanted."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Engagement Models"
                           Text="Easy hiring with a flexible engagement model, allowing you to build projects with an A-team. You can scale up and down the team anytime you want."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Easy Project Monitoring"
                           Text="Easy project monitoring with a project manager, we provide you regular updates through mails, calls and chat to keep you posted."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;