

import React, { Component, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import techImg1 from '../../../assets/img/technology/style2/1.svg';
import techImg2 from '../../../assets/img/technology/style2/2.svg';
import techImg3 from '../../../assets/img/technology/style2/3.svg';
import techImg4 from '../../../assets/img/technology/style2/4.svg';
import techImg5 from '../../../assets/img/technology/style2/5.svg';

import techImg7 from '../../../assets/img/technology/style2/7.svg';

import techImg10 from '../../../assets/img/technology/style2/10.svg';
import techImg9 from '../../../assets/img/technology/style2/9.svg';
import techImg8 from '../../../assets/img/technology/style2/8.svg';

import techImg13 from '../../../assets/img/technology/style2/13.svg';
import techImg12 from '../../../assets/img/technology/style2/12.svg';
import techImg15 from '../../../assets/img/technology/style2/15.svg';
import techImg17 from '../../../assets/img/technology/style2/17.svg';
import techImg18 from '../../../assets/img/technology/style2/18.svg';
import techImg19 from '../../../assets/img/technology/style2/19.svg';
import techImg20 from '../../../assets/img/technology/style2/20.svg';
import techImg21 from '../../../assets/img/technology/style2/21.svg';
import techImg22 from '../../../assets/img/technology/style2/22.svg';
import techImg23 from '../../../assets/img/technology/style2/23.svg';
import techImg24 from '../../../assets/img/technology/style2/24.svg';
import techImg25 from '../../../assets/img/technology/style2/25.svg';
import techImg26 from '../../../assets/img/technology/style2/26.svg';
import techImg28 from '../../../assets/img/technology/style2/28.svg';
import techImg32 from '../../../assets/img/technology/style2/32.svg';
import techImg33 from '../../../assets/img/technology/style2/33.svg';
import techImg34 from '../../../assets/img/technology/style2/34.svg';
import techImg35 from '../../../assets/img/technology/style2/35.svg';
import techImg36 from '../../../assets/img/technology/style2/36.svg';
import techImg37 from '../../../assets/img/technology/style2/37.svg';
import techImg38 from '../../../assets/img/technology/style2/38.svg';
import techImg39 from '../../../assets/img/technology/style2/39.svg';
import techImg40 from '../../../assets/img/technology/style2/40.png';
import techImg45 from '../../../assets/img/technology/style2/45.svg';
import techImg41 from '../../../assets/img/technology/style2/41.png';
import techImg48 from '../../../assets/img/technology/style2/48.svg';



const ServicesTabs = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    let tab1 = "Front-end",
        tab2 = "Back-end",
        tab3 = "Mobile Development",
        tab4 = "Database",
        tab5 = "Info & Cloud"   
    const tabStyle = 'nav nav-tabs';
    return (
        <div className="tab-area">
            <Tabs>
                <div className="row">
                    <div className="col-lg-12">
                        <TabList className={tabStyle}>
                            <Tab>
                                <h3>{tab1}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab2}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab3}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab4}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab5}</h3>
                            </Tab>
                            
                        </TabList>
                    </div>
                    <div className="col-lg-12 pt-40 pb-30 md-mb-30">
                        <div className="tab-content">
                            <TabPanel>
                                <div className="image-wrap">
                                  <div class="react-tabs text-center">
                                        <div className="rs-technology">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                        
                                                            <div className="logo-img">
                                                                <img src={techImg1} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                            <div className="logo-img">
                                                                <img src={techImg2} alt="" />
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg4} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg5} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg9} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div> 

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                        
                                                            <div className="logo-img">
                                                                <img src={techImg8} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg45} alt="" />
                                                            </div>
                                                     
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                            <div className="logo-img">
                                                                <img src={techImg22} alt="" />
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <div className="rs-technology">
                                                <div className="row">

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg2} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg15} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>


                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                        
                                                            <div className="logo-img">
                                                                <img src={techImg19} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg20} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg21} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg10} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg26} alt="" />
                                                            </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                        
                                                            <div className="logo-img">
                                                                <img src={techImg12} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                            </div>
                                    </div>
                                </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <div className="rs-technology">
                                                <div className="row">
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                            
                                                                <div className="logo-img">
                                                                    <img src={techImg48} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                            
                                                                <div className="logo-img">
                                                                    <img src={techImg13} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                            
                                                                <div className="logo-img">
                                                                    <img src={techImg18} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg17} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <div className="rs-technology">
                                                <div className="row">

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img" style={{width:"100px"}}>
                                                                    <img src={techImg32} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg33} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <h3 class="poppins-bold"></h3>
                                            <div className="rs-technology">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                            <img src={techImg28} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>

    );
}

export default ServicesTabs;