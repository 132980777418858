import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-80 pb-80">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text gold-color"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our UI & UX Design Services are functional, practical, and result-oriented"
                        descClass="desc mb-25"
                        description="As you reach out to our UI/UX team you tap into an umbrella that has many services under it. Define your brand and impress your users with our design services that make your app’s navigation smooth. 
                        "
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Market Research & Analysis"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Front-end Development"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Fully Functional UX/UI"
                                 />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Concept Presentation"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="UI Testing Process"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="Prototype Testing "
                                 />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default OurServices;