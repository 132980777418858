import React from 'react';

const ContactForm = (props) => {
    const { submitBtnClass } = props;
    return (
        <form id="contact-form" action="#">
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" placeholder="Name" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="email" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="website" name="website" placeholder="Your Website" required />
                </div>
                <div className="col-md-6 mb-30">
                        <select className="from-control" name="experience" id="experience"  placeholder="Experience" required>
                            <option>Experience</option>
                            <option value="0-1">0-1 Years</option>
                            <option value="2-4">2-4 Years</option>
                            <option value="5+">5+ Years</option>
                        </select>
                </div>
                <div className="col-md-6 mb-30">
                    <select className="from-control" name="job_id" id="job_is" placeholder="Experience" required>
                        <option value="Job Title">Job Title</option>
                        <option value="1">iOS Developer</option>
                        <option value="2">Business analyst</option>
                        <option value="3">Android Developer</option>
                        <option value="4">Flutter Developer</option>
                        <option value="5">Quality Analyst</option>
                        <option value="6">Golang developer</option>
                    </select>
                </div>
                <div className="col-12 mb-30">
                <input className="from-control" type="file" id="fileUpload" name="fileUpload" placeholder="Upload Your file" required />
                </div>
                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">Submit Now</button>
            </div>
        </form>
    );

}

export default ContactForm;