import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/social-media.png';

const AboutSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 style3 pt-80 pb-80 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Best Features of Our Social Media Application
                                    "
                                    descClass="desc text-white"
                                    description="Would you like to have a knowledgeable team that knows your target audience, and builds a social media app that maximizes your profits? We create unique and highly interactive apps tailor-made, and packed with amazing features."
                                />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Maps Integration</li>
                                        <li className="text-white">Push notifications</li>
                                        <li className="text-white">Real-time chat </li>
                                        <li className="text-white">Story sharing </li>
                                        <li className="text-white">Chatbots integration</li> 
                                        <li className="text-white">Geo-tagging</li>
                                        <li className="text-white">Video uploading</li>
                                        <li className="text-white">AI &amp; AR Integration</li>
                                        <li className="text-white">Marketplace Option </li>
                                        
                                        <li className="text-white">Blockchain Integration</li>
                                    </ul>
                                </div>
                                <div className='col-lg-8 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Media Sharing &amp; Upload</li>
                                        <li className="text-white">Media upload &amp; sharing  </li>
                                        <li className="text-white">Social media API integration </li>
                                        <li className="text-white">Secure login and privacy controls</li>
                                        <li className="text-white">Content sharing, Audio/Video chat, recommendation</li>
                                        <li className="text-white">Advance setting includes private, personal details, preferences & more</li>
                                        <li className="text-white">Simple and easy to connect & chat with groups</li>
                                        <li className="text-white">Attractive profile features including background image, color, font style, wallpaper, level, etc.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider