import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Why Pick Us To Develop Wellness & Fitness Apps?
                    "
                    descClass="desc text-dark"
                    description="We get back to coding the best possible wellness and fitness solutions. 
                    We serve diverse client requirements and provide the latest solutions at affordable prices. You get timely updates on our projects and finally see your app bloom. Whether you are a gym owner, yoga guru, personal trainer, or nutritionist, we have got you covered. Why should you choose us over many other app development companies? Here’s why: 
                    "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Customer Satisfaction"
                            serviceURL="#"
                            Text="Using the trendiest tools and our expertise in the wellness industry, we craft sheer secure, scalable, and user-friendly solutions to impress every fitness freak."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Flexible Engagement Models"
                            serviceURL="#"
                           Text="We do not push clients to operate under rigid and rigorous limitations by providing flexibility in engagement and employment approaches."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Integrity & Transparency"
                            serviceURL="#"
                            Text="Your suggestions are always welcome. We inform our project updates to you frequently. 
                            We maintain transparency and Integrity as we care about your vision and idea.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Dedicated Teams"
                            serviceURL="#"
                            Text="Lap up the modern fitness solution built by our dedicated crew, who is technically expert in dealing with various frameworks."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Methodology
                            "
                            serviceURL="#"
                            Text="Our agile methodologies ensure the timely delivery of your projects. Our flexible practices keep our clients enthusiastic about their projects from design to delivery & further. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Competitive Rates"
                            serviceURL="#"
                            Text="We offer you a satisfying blend of competitive rates and outstanding quality. That’s what makes us a favorite among many others who do the same job."
                        />
                    </div>
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Interested in Developing Your Own Fitness & Wellness Solutions?"
                ctaTextClass="exp-text"
                ctaText="With our digital app development solutions, we can cater to the specific needs and demands of your business."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;