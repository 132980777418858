import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/egml-2.png';
import aboutImg2 from '../../assets/img/about/egml-1.png';
import aboutImg3 from '../../assets/img/about/egml-3.png';

const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 0,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
            <div className="rs-businessmodels style3 pt-80 pb-80 md-pb-0" >
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our services are at the forefront"
                        titleClass="title pb-10"
                        title="How do we engage to make a difference?"
                        descClass="desc"
                        description="Are you scratching your head about digital issues? Rely on us for the array of flexible solutions that meet your business needs online. We make the most of the latest technology to tailor the results that are unique to your company. Our engagement models ensure maximum benefits. Mobile apps, Website Designs, Innovative Software - you name it, we have got the best of everything digitally trending under our belt."
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg1}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-20 md-pl-15'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 pb-5"
                                            title="Dedicated Talent"
                                            descClass="desc2"
                                            description="How about having a friendly team who delivers apt digital solutions? - A team of talented professionals who work exclusively on your projects. 
                                            The best part is - You are in control of your software solutions. 
                                            "
                                        />
                                        <ul className="check-square">
                                            <li className="">No Long term commitment</li>
                                            <li className="">No fixed costs, Pay as you go</li>
                                            <li className="">Get your needs covered</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg3}
                                            alt=""
                                        />
                                    </div>
                                </div> 
                                <div className='col-lg-7 pl-20 md-pl-15'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 pb-5"
                                            title="Fixed Cost Projects"
                                            descClass="desc2"
                                            description="Our fixed cost strategy can help you manage your budget. Ideal for generating proofs of concept or putting your unique ideas into action."
                                        />
                                        <ul className="check-square">
                                            <li className="">Fixed Timeline</li>
                                            <li className="">Minimal project risk</li>
                                            <li className="">No hidden costs</li>
                                            <li className="">Transparent progress monitoring</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                               <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg2}
                                            alt=""
                                        />
                                    </div>
                                </div> 
                                <div className='col-lg-7 pl-20 md-pl-15'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 pb-5"
                                            title="Build Your Team"
                                            descClass="desc2"
                                            description="Transform your business ideas into reality with our cross-functional teams - Your reliable and receptive partners in making your business bloom."
                                        />
                                        <ul className="check-square">
                                            <li className="">Easy accessibility</li>
                                            <li className="">Saves time and cost</li>
                                            <li className="">Unique selection process of Screening, Interview, Assignment & Pilot project</li>
                                            <li className="">Acts as your extended team member</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider