import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Flutter Developers from Metaqualt to hire. Why choose MetaQualt? Here’s why:"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Real Experience"
                           
                            Text="Your app’s user experience is directly proportional to the experience of your app developers. We have been building flutter applications since the launch of the technology. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Proven Knowledge"
                            
                            Text="Both you and your end users get amazed each time we put our technical skills in Dart programming language at work."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Community"
                           
                            Text="Why should you trust the community and the quality of its solution? Long answer short, Flutter is a platform created by Google! 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Native Development Background"
                            
                           Text="As a cross-platform application, Flutter requires a native-like experience to develop top-notch app experiences. And we have that kind of experience! 
                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Test-Driven Development"
                           
                            Text="Before you wow your customers with our product, it goes through our sincere checks to ensure a quality-driven app development. 

                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Automation Tools"
                           
                           Text="Hang your hat on us! We provide swift development of applications with the tools that reduce the development efforts without losing the quality; not even by a bit. 
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;