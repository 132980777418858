import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg81 from '../../assets/img/service/style2/main-img/81.png';
import serviceImg82 from '../../assets/img/service/style2/main-img/82.png';
import serviceImg83 from '../../assets/img/service/style2/main-img/83.png';
import serviceImg84 from '../../assets/img/service/style2/main-img/84.png';
import serviceImg85 from '../../assets/img/service/style2/main-img/85.png';
import serviceImg86 from '../../assets/img/service/style2/main-img/86.png';
import serviceImg87 from '../../assets/img/service/style2/main-img/87.png';
import serviceImg88 from '../../assets/img/service/style2/main-img/88.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-100 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Our Travel App Development Expertise"
                    titleClass="title new-title pb-20"
                    title="Power up your tourism goals with our mind-blowing solutions."
                    descClass="desc"
                    description="You can make the right decision by choosing our reliable services; for we have built ample satisfying applications for various customers. You get the most suitable app that meets your business goals. Your brand gets viral in no time after we deliver you the best-in-class business app. Make us your app development partner and watch your app speed up toward success, attract clients, and increase your profits."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg81}
                            Title="Ticket Booking Solution" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg82}
                            Title="Travel Planning Solution"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg83}
                            Title="Travel Management App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg84}
                            Title="Hotel Reservation App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg85}
                            Title="Vehicle Rental Booking" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg88}
                            Title="Virtual Tours App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg86}
                            Title="Journey Planner Portal"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg87}
                            Title="Trip Management App"
                        />
                    </div>
                   
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

