import React from 'react';
import OurServices from './weProvide';
// import WeServes from './it-services';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';

import WhyChoose from './whychooseus';

import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import CTA from '../../components/Common/CTA';
import SingleServiceFour from '../../components/Service/SingleServiceFour';

// Working Process Images
import processImagePR1 from '../../assets/img/process/PR1.png';
import processImagePR2 from '../../assets/img/process/PR2.png';
import processImagePR3 from '../../assets/img/process/PR3.png';
import processImagePR4 from '../../assets/img/process/PR4.png';

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';


// Service Icons
import full_stack from '../../assets/img/about/full_stack.png';
import bannerImg from '../../assets/img/about/dedicated2.png';
import shapeImg from '../../assets/img/technology/style2/full_stack_icon.png';

const HireFullStackDevelopers = () => {

    return (
        <React.Fragment>
           
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about pt-150 pb-20 md-pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                             <div className="text-center relative">
                                 <img className="main" src={bannerImg} alt=""/>
                            </div>
                            <div className="banner-img absolute" style={{top: 25, left:0}}>
                                <img
                                    className="layer-img dance"
                                    src={shapeImg}
                                    alt=""
                                />                    
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pb-70 pt-70 md-pt-10 md-pb-20"
                                subtitleClass="sub-text gold-color"
                                subtitle="Hiring"
                                titleClass="title pb-25"
                                title="Hire Dedicated Full Stack Developers
                                "
                                descClass="desc mb-25"
                                description="
                                Hire us today for well-honed web, mobile, and desktop applications. If you are in quest of exceptional talents to provide you with end-to-end full-stack development service, you have found the right team. We have profound experience in various software development stages, servers, APIs, MVC, databases,
                                hosting environments, and much more. We have skills, tools, and the latest technology at our disposal to give you a sharp edge in your niche industry.                                 
                                "
                                secondDescClass="mb-10 pb-16"
                                secondDescription="We provide you with an application that stuns your end users and helps in scaling your business better and faster. Get modernized solutions with our new hiring models. You can hire us on a full-time, part-time, or hourly basis. 
                                "
                            />                           
                        </div>
                    </div>
                </div>
            </div>
            {/* about-area-start */} 

            {/* counter area start */}
            {/* <CounterHomeFive /> */}
            {/* counter area end */}

            <div className="style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">What's We Provide</h2>
                </div>
                <div className='row align-items-center'>
                        
                    <div className='col-lg-7 col-md-12'>
                        <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        subtitleClass="sub-text gold-color"
                                        subtitle=""
                                        titleClass="title pb-10"
                                        title="Looking to build Dynamic Web Solutions? Hire Us."
                                        descClass="desc"
                                        description="Depend on us for full mobile or web app development. Make the most of our unprecedented skills to develop front and back-end solutions that not just meet your goals but take your enterprise to the next level. Rely on the best techies to get the job done. Here’s how you can benefit from us: 
                                        "
                                    />
                                </div>
                            
                                <div className='col-lg-6 col-md-12'>
                                    <ul className="check-square">
                                        <li className="">Competent partnershipy</li>
                                        <li className="">Multiple Proficiency</li>
                                        <li className="">Cheaper Solution for Better Result</li>
                                        <li className="">Flexibility</li>
                                        <li className="">All-in-one Solution</li>
                                            
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <ul className="check-square">
                                        <li className="">Additional Skills</li>
                                        <li className="">Effective Troubleshooting</li>
                                        <li className="">Wider View</li>
                                        <li className="">User-friendly approach</li>
                                        <li className="">High responsibility level</li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center relative">
                                <img className="main" src={full_stack} alt=""/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

             {/* about-area-start */}
			{/* <WeServes /> */}
			{/* about-area-end */}

            {/* Project-start */}
			<OurServices />
			{/* Project-end */}     

            {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

            {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */}  

            {/* newsletter-area-start */}
			{/* <div className="mb-50">
                <div className="container rs-call-us relative pt-80 pb-80">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rs-contact-box text-center">
                                <CTA
                                    ctaSectionClass="rs-cta style1 text-center"
                                    ctaTitleClass="epx-title"
                                    ctaTitle="Share Your Want List. We’ll Get It Done From There."
                                    ctaTextClass="exp-text"
                                    ctaText="Really, we mean it. Jot down the complete details you want to include in your project. Tech-savvy we are here to share the expertise you required yesterday and deliver outstanding results."
                                    ctaButtonClass="readon learn-more"
                                    ctaButtonLink="./careers"
                                    ctaButtonText="Share Your Idea"
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div> */}
			{/* newsletter-area-end */}

            {/* working-process-area-start */}

             {/* <div className="rs-process style2 pt-40 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Hiring Process"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="We have made our hiring process highly transparent. Here is how easy it is to find the best-dedicated development team and get started on your project."
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR1}
                                titleClass="title"
                                Title="Share your project"
                                Text="Share a detailed project copy with us to give us an understanding of your project needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR2}
                                titleClass="title"
                                Title="Interview the resources"
                                Text="Once we have the details, we would share with you the best available resources that fit your needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR3}
                                titleClass="title"
                                Title="Select the resources"
                                Text="Select the resources and knowledge about our pricing models, time of development, and finalize."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR4}
                                titleClass="title"
                                Title="Sign a contract"
                                Text="Last, sign an NDA with our company, and we get started on the development of the project."
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			{/* <Brand /> */}
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default HireFullStackDevelopers;