import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactUsForm from '../../components/Contact/ContactUsForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';
// import CounterDefault from '../../components/Elements/Counter/CounterDefault';

// import contactHereImg from '../../assets/img/about/contact-here.png';


const ContactMain = () => {

        return (
            <React.Fragment>

                {/* Contact Section Start */}
                <div className="rs-contact rs-why-choose style2 pt-120 pb-80 md-pt-60">
                    <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text style-bg"
                            subtitle="Let's start the discussion"
                            titleClass="title title"
                            title="Get the ball rolling with us and step into the future of technology.
                            "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="If you liked what you saw and are looking forward to talking to us, or just seeking more information regarding the project’s timeline or budget, please feel free to reach out. With our skills and transparent pricing model, we're sure we can provide what you're looking for at a rate you can afford.

                            If any idea hits your mind or if you want to know more about our services and prices, feel free to connect. Your brand needs a public eye and we have got a tempting solution for games, e-commerce, blog, and various business sectors.
                            
                            You are not far away from an innovative solution for your enterprise. Fill out the below form. We will get in touch and discuss your good ideas. 
                            "
                            
                        />
                        <div className="row bx-shadow">
                            <div className="col-lg-5 col-md-12 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-7 col-md-12 pl-30 md-pl-14">
                                <div className="contact-widget">
                                     {/* Contact Form */}
                                    <ContactUsForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact Section End */}

                {/* Contact Section Start */}
                <div className="rs-call-us relative pt-50 pb-50 mb-0">
                        
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-lg-5 hidden-md">
                                    <div className="image-part">
                                        <img src={contactHereImg} alt="" />
                                    </div>
                                </div> */}
                                <div className="col-lg-12 pt-50 pb-50 md-pb-50">
                                    <div className="rs-contact-box text-center">
                                        
                                        <SectionTitle
                                            sectionClass="sec-title3 text-center"
                                            subtitleClass="sub-text style-bg"
                                            subtitle="24/7 Availability"
                                            titleClass="title"
                                            title="Book a call with a trusted tech companion and grow your business
                                            "
                                            descClass="desc"
                                            description="When it comes to a mobile app or a website that reinforces your ideas, our developers hit the spot. Our experts always anticipate designing and developing a trendy, affordable, and profitable product for you. 
                                            "
                                        />
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                {/* Contact Section End */}

                {/* scrolltop-start */}
                 <ScrollToTop />
                {/* scrolltop-end */}


            </React.Fragment>

        );
}


export default ContactMain;