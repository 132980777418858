import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/service/style2/main-img/S5.png';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="What can our Business Applications achieve?"
                                    descClass="desc text-white"
                                    description="As an enterprise application development company, MetaQuotes possesses a lot of expertise and knowledge that let us develop outstanding, complex, and customized apps for businesses. We offer best B2C and B2B portal development services to help them compete in this highly competitive market. Our company first focuses on understanding the target audience of your project as soon as you assign it to us. Having this information helps us to build tailored apps for your business."
                                />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Rapid Development & Deployment</li>
                                        <li className="text-white">Web & Mobile Applications</li>
                                        <li className="text-white">Endless Integration Capabilities</li>
                                        <li className="text-white">Built-In Collaboration Tools</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Multi-Cloud Native by Default</li>
                                        <li className="text-white">Version Control & App Quality</li>
                                        <li className="text-white">Marketplace & Community Support</li>
                                        <li className='text-white'>Multiple Payment Options Support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;