import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import E_commerce_app_solution from '../../components/Common/Breadcumb/e_commerce_app_solution';
import EcommerceSolutions from './ecommerce-solutionsMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const EcommerceSolutionsMain = () => {

    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='about'
                 activeMenu='/about'
            />
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                <E_commerce_app_solution
                    pageTitle="E-Commerce Solutions"
                    titleClass="page-title"
                    pageName="E-Commerce Solutions"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="E-Commerce App"
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <EcommerceSolutions />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default EcommerceSolutionsMain;