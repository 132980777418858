import React from 'react';
import OurServices from './weProvide';
import WeServes from './it-services';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import Brand from '../../components/Common/Brand';
import WhyChoose from './whychooseus';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import CTA from '../../components/Common/CTA';
import SingleServiceFour from '../../components/Service/SingleServiceFour';

// Working Process Images
import processImagePR1 from '../../assets/img/process/PR1.png';
import processImagePR2 from '../../assets/img/process/PR2.png';
import processImagePR3 from '../../assets/img/process/PR3.png';
import processImagePR4 from '../../assets/img/process/PR4.png';

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
// Service Icons
import Hirereact from '../../assets/img/about/Hirereact.png';
import bannerImg from '../../assets/img/about/dedicated2.png';
import shapeImg from '../../assets/img/technology/style2/react_js.png';

const HireReactJsDevelopersPage = () => {

    return (
        <React.Fragment>
           
            {/* <!-- banner section end --> */}
            <div id="rs-about" className="rs-about pt-150 pb-20 md-pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                             <div className="text-center relative">
                                 <img className="main" src={bannerImg} alt=""/>
                            </div>
                            <div className="banner-img absolute" style={{top: 25, left:0}}>
                                <img
                                    className="layer-img dance"
                                    src={shapeImg}
                                    alt=""
                                />                    
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 pb-70 pt-70 md-pt-10 md-pb-20"
                                subtitleClass="sub-text gold-color"
                                subtitle="Hiring"
                                titleClass="title pb-25"
                                title="Hire Dedicated ReactJS Developers"
                                descClass="desc mb-25"
                                description="MetaQualt is a place where your modern needs meet our modern solutions. 
                                Apart from the need for long experience with the latest and well-established technologies, we are aware that it takes in-depth frontend and backend knowledge to develop user experiences like never before.
                                "
                                secondDescClass="mb-10 pb-16"
                                secondDescription="By making us your partners in React JS development project, you are on your way to getting futuristic and scalable applications. We make your dreams come true with our profound front-end and back-end experience. Hire our serious ReactjS developers for API integration, microservices, and backend development for end-to-end product development. Relish a user experience that’s second to none! 
                                "
                            />                           
                        </div>
                    </div>
                </div>
            </div>
            {/* about-area-start */} 

            {/* counter area start */}
            {/* <CounterHomeFive /> */}
            {/* counter area end */}

            {/* about-area-start */}
            <div className="style3 pt-100 pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        
                        <div className='col-lg-7 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle=" "
                                    titleClass="title pb-10"
                                    title="Creating a Dynamic app is the challenge. Hiring a ReactJS Developer is the solution."
                                    descClass="desc"
                                    description="Big brands like Netflix, Facebook, Uber Eats, and more are benefiting from the sheer power of ReactJS. You can be the next one! Check some qualities of our ReactJS services below: 
                                    "
                                />
                                </div>
                                <div className='col-lg-7 col-md-12'>
                                    <ul className="check-square">
                                        <li className="">Simplified development process</li>
                                        <li className="">Best to build interactive mobile interfaces</li>
                                        <li className="">User-friendly developer toolset</li>
                                        <li className="">Extensible and Integrable</li>
                                        <li className="">Readable, flexible & secure</li>
                                    </ul>
                                </div>
                                <div className='col-lg-5 col-md-12'>
                                    <ul className="check-square">
                                        <li className="">Stable code</li>
                                        <li className="">Smoother navigation</li>
                                        <li className="">Robust community</li>
                                        <li className="">SEO-friendly</li>
                                        <li className="">Worldwide popularity</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center relative">
                                    <img className="main" src={Hirereact} alt=""/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* about-area-end */}

            {/* about-area-start */}
			{/* <WeServes /> */}
			{/* about-area-end */}

            {/* Project-start */}
			<OurServices />
			{/* Project-end */}     

            {/* why choose us area start */}
            <WhyChoose />
            {/* why choose us area start */}

            {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			{/* <div className="mb-50">
                <div className="container rs-call-us relative pt-80 pb-80">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rs-contact-box text-center">
                                <CTA
                                    ctaSectionClass="rs-cta style1 text-center"
                                    ctaTitleClass="epx-title"
                                    ctaTitle="Share Your Want List. We’ll Get It Done From There."
                                    ctaTextClass="exp-text"
                                    ctaText="Really, we mean it. Jot down the complete details you want to include in your project. Tech-savvy we are here to share the expertise you required yesterday and deliver outstanding results."
                                    ctaButtonClass="readon learn-more"
                                    ctaButtonLink="./careers"
                                    ctaButtonText="Share Your Idea"
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </div> */}
			{/* newsletter-area-end */}

            {/* working-process-area-start */}

             {/* <div className="rs-process style2 pt-60 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Hiring Process"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="We have made our hiring process highly transparent. Here is how easy it is to find the best-dedicated development team and get started on your project."
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR1}
                                titleClass="title"
                                Title="Share your project"
                                Text="Share a detailed project copy with us to give us an understanding of your project needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR2}
                                titleClass="title"
                                Title="Interview the resources"
                                Text="Once we have the details, we would share with you the best available resources that fit your needs."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR3}
                                titleClass="title"
                                Title="Select the resources"
                                Text="Select the resources and knowledge about our pricing models, time of development, and finalize."
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImagePR4}
                                titleClass="title"
                                Title="Sign a contract"
                                Text="Last, sign an NDA with our company, and we get started on the development of the project."
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            
            {/* working-process-area-end */}           

            {/* brand-area-start */}
			{/* <Brand /> */}
			{/* brand-area-end */}

        </React.Fragment>
    )
}

export default HireReactJsDevelopersPage;