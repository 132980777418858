import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
// import SiteBreadcrumb from '../../components/Common/Breadcumb';
import Ios_app_devloper from '../../components/Common/Breadcumb/ios_app_developer';
import HireiOSAppDevelopers from './hireiosappDevelopersMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const HireiOSDeveloperMain = () => {

    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='Hire iOS App Developers'
                 activeMenu='/Hire Developers'
            />
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                <Ios_app_devloper
                    pageTitle="Hire Dedicated iOS App Developers"
                    titleClass="page-title"
                    pageName="Hire Dedicated iOS App Developers"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Hire iOS App Developers"
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <HireiOSAppDevelopers />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default HireiOSDeveloperMain;