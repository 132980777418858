import React from 'react';
import { Link } from 'react-router-dom';

const SingleProjectTwo = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="project-img">
                <Link to={props.ProjectURL}>                    
                    <img 
                        src={props.projectImage} 
                        alt={props.Title}
                    />                    
                </Link>                
            </div>
            <div className="project-content">
                <div className="portfolio-inner">
                <h3 className="title">
                    <Link to={props.ProjectURL}>
                        {props.Title}
                    </Link>
                </h3>
                <p className="number-txt">{props.Text}</p>
                </div>
            </div>
        </div>
	)
}

export default SingleProjectTwo