import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon40 from '../../assets/img/service/style2/main-img/40.png';
import mainIcon43 from '../../assets/img/service/style2/main-img/43.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hiring UX/UI Developers from Metaqualt is worth your time and money?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="User-Centric Approach"
                           Text="As you hire us, rest assured about getting interfaces that your users find irresistible. 
                           We design user-centric apps and software that evoke action from your visitors."
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon43}
                            hoverIcon={mainIcon43}
                            Title="Decade of Experience"
                           Text="Our designers have over a decade of experience in designing apps that make impact. 
                           We know what’s trending and what will make your app stand out from the crowd."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon40}
                            hoverIcon={mainIcon40}
                            Title="Wide-Ranging Expertise"
                            Text="Our experience in more than 15 industries makes brands as you choose us for your app development project. We keep a track of trending changes in your industry."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On-Time Delivery"
                            Text="We value your time. It takes on-time product delivery to build trust like we at MetaQualt 
                            have built over the years."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon4}
                            hoverIcon={mainIcon4}
                            Title="Use of Advanced Technologies"
                           Text="Our tech consultants will come up with the right solution based on your needs and with the use of advanced tools and technologies that we are well versed in. "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Higher Engagement"
                           Text="We design to impress and convert! Our years of experience come together to design an irresistible and rewarding app that never fails to attract your end users."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;