import React from "react";
// import TypeWriter from "typewriter-effect";
import Typed from 'react-typed';

const TypeEffect=()=> {
  return (
    <div className="typewriter">
       <Typed
                    strings={['attract clients',' offer values',' smoothen processes','generate revenue']}
                    typeSpeed={100}
                    loop={true}
                    backSpeed={100}
                    
        />
        
      

      {/* <TypeWriter

        onInit={(typewriter)=> {

        typewriter

        .typeString("Web Development") 
        .pauseFor(1500)
        .deleteAll()
        .typeString("Mobile App Development")
        .pauseFor(1000)
        .deleteAll()
        .typeString("")
        .pauseFor(500)
        .deleteAll()
        .typeString("Product Development")  
            
        .start();
        
        } }
       /> */} 
    </div>
  );
}
  
export default TypeEffect;