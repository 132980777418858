import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons

import serviceImg57 from '../../assets/img/service/19.png';
import serviceImg58 from '../../assets/img/service/20.png';
import serviceImg59 from '../../assets/img/service/21.png';
import serviceImg60 from '../../assets/img/service/22.png';
import serviceImg64 from '../../assets/img/service/23.png';
import serviceImg61 from '../../assets/img/service/24.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Social Networking"
                    titleClass="title new-title"
                    title="Our Real-Time Social Networking App Solutions."
                    descClass="desc"
                    description="If you want to boost your social media outreach in real time, look no further. We offer you the best social media app development services that adhere to your brand’s goals. To make things come true, we use highly advanced tools and technologies. We have a solid experience with numerous successful social media projects. You can be our next satisfied client who can witness their business scale up to a great extent."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg57}
                            Title="Messaging App" 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg58}
                            Title="Social Networking Portal"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg60}
                            Title="Mobile Dating Apps" 
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg64}
                            Title="Social News Sharing App"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg59}
                            Title="Image/video Sharing App"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg61}
                            Title="Community App Development"
                        />
                    </div>
                   
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

