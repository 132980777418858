import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';


const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="What Makes Metaqualt Python Developers the Best?
                    "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Expert Team"
                           
                            Text="Choose our team, having a beautiful portfolio of secure, scalable, appealing, and reliable apps. We have over a decade of experience in Python development. 
 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Efficient Development Approach"
                            
                            Text="We love our work and are equally interested in your project requirements and brand values. Our approach toward each project is customer-centric."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Full-stack Tech Proficiency"
                           
                            Text="Our coding freaks hold full-stack tech proficiency. With our front and back-end solutions, it’s always a win-win-win. You win, we win, and your end users win. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Robust Delivery Cycle"
                            
                           Text="Providing a top-notch Python development service has become a routine for us.  
                           Our solid delivery cycle makes us deliver your project at the given time.
                           "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Choose from Large Pool"
                           
                            Text="You get to choose from a large pool of Python developers who are no strangers to  
                            technologies such as JavaScript, jQuery, celery Django, Flask, & MySQL.
                             
                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Future Proof Websites"
                           
                           Text="With our safe, scalable, and engaging web apps, you can always anticipate a bright future. Your website is future-proof! 

                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;