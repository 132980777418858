import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Salient features of our Business App Development services."
                    descClass="desc text-dark"
                    description="We offers clients affordable mobile business solutions through its enterprise app development services. We always go the extra mile to ensure you're happy with our team of enterprise application developers. Choosing your partner makes sense for a number of reasons. Our company will provide you with exceptional business app development services whenever you choose us as your partner enterprise app development company."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="100% Client Satisfaction"
                            Text="As per your requirements, we deliver user-friendly, scalable, secure & new business solutions that are user-friendly, scalable & secure."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Business Value"
                            Text="We offer business software solutions promising the right fit for the present business model of the clients."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Collaborative Approach"
                            Text="Our collaborative approach assures clients that we support them at every stage from development, designing, testing, etc."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Eye-Catching User Experience"
                            Text="To enhance the long-term customer interaction with your company, we deliver an eye-catching user experience."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            Text="Using the Agile methodology, we conduct frequent scrum events to optimize practices and methods."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="On-Time Project Delivery"
                            Text="We take the project deadlines very seriously and so; we always focus on delivering the projects on time."
                        />
                    </div>
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Get Scalable App Development Services"
                ctaTextClass="exp-text"
                ctaText="We provide end-to-end application design, integration, and administration solutions."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;