import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-80 pb-80">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text gold-color"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our outstanding ReactJS Development Services"
                        descClass="desc mb-25"
                        description="React Js is about to overpower every other technology in the domain. How come? Big names like Facebook and Google trust it. How about trusting what the big brands trust? Hire our React JS developers to hop ahead in your niche market. With us, you are on your way to becoming the next industry leader."
                         effectClass="heading-line"
                />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Cross-platform app development"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="ReactJS Migration & Integration"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="ReactJS Plugin Development"
                                 />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="ReactJS UI development"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="Support & Migration Services"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="QA & Testing Services"
                                 />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default OurServices;