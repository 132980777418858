import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/main-home/softwere-devlop.png';
import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.png';
import mainIcon26 from '../../assets/img/service/style2/main-img/26.png';
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="What’s so special about the AngularJS Developers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="No hidden costs"
                           Text="Rest assured, You will never be charged for anything apart from what we quote. We keep absolute transparency. 
                           "
                        />
                    </div>
                    
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="02"
                            mainIcon={mainIcon34}
                            hoverIcon={mainIcon34}
                            Title="Easy Prescreening"
                           Text="We allow you to prescreen our resources before hiring them to ensure the quality of work."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="03"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Simple Coding"
                            Text="Our topmost priority is the code’s quality. Take our promise of bug-free and well-structured codes as gospel."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="04"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Tech Consultation"
                            Text="Feel free to share your projects and get a free consultation about the technical needs from our tech geniuses.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon26}
                            hoverIcon={mainIcon26}
                            Title="App Launch"
                           Text="Consult us and get ready for a significant app launch. We deliver end-to-end Angular development services that include development, design, and deployment."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Clients-centric Approach"
                           Text="We just don’t put on the ritz to impress you. We make sure to give you a brilliant solution that not only looks well but sticks to your business needs and performs effectively."
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;