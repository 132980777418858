import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';


const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Full Stack Developers from Metaqualt are the right people to hire?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Pay only for the measurable work"
                           
                            Text="Our world-class development service won’t cost a bundle. Hire dedicated resources on an hourly basis. You pay only for the measurable work done.  
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Build your team"
                            
                            Text="Thoroughly interview us, and share your ideas. Our techno bees extract the honey of best development practices from the beehives packed with the latest tools and technologies."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Out-of-the-box Solutions"
                           
                            Text="Looking for groundbreaking solutions? Count on us for building a wide array of software solutions including ERP, CRM, CMS, SaaS, etc."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="First time right"
                            
                           Text="Understanding the challenges of your business, our passionate developers see to it that you get nothing but quality outputs and customer satisfaction."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Complete In-house development"
                           
                            Text="
                            We have outgrown the cliched ways of development and provide a unique solution as per the client's needs. We do everything in-house. All our talents reside within our company. 
                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Guaranteed Results"
                           
                           Text="Our team is obsessed with coding and building revolutionary applications. What you get is an utterly stunning yet reliable and efficient website with our full-stack development service. "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;