import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;
    return (
        <React.Fragment>
           
            <li className={parentMenu === '' ? 'current-menu-item' : ''}>
                <Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Main</Link>
            </li>

            
            <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/careers" className={activeMenu === "/careers" ? "active-menu" : ""}>Careers</Link>
                    </li>
                    <li>
                        <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
                    </li>
                    <li>
                        <Link to="/blog" className={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link>
                    </li>
                </ul>
            </li>

            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/service" className={activeMenu === "/services" ? "active-menu" : ""}>Services</Link>
                
                <ul className="sub-menu">
                    <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                        <Link to="/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/php-development" className={activeMenu === "/php-development" ? "active-menu" : ""}>PHP Development</Link>
                            </li>
                            <li>
                                <Link to="/laravel-development" className={activeMenu === "/laravel-development" ? "active-menu" : ""}>Laravel Development</Link>
                            </li>
                            <li>
                                <Link to="/codeigniter-development" lassName={activeMenu === "/codeigniter-development" ? "active-menu" : ""}>Codeigniter Development</Link>
                            </li>
                            <li>
                                <Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UX/UI Design Development</Link>
                            </li>
                        </ul>
                    </li>
                    <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link>   
                        <ul className="sub-menu">
                            <li>
                                <Link to="/android-app-development" lassName={activeMenu === "/android-app-development" ? "active-menu" : ""}>Android App Development</Link>
                            </li>
                            <li>
                                <Link to="/ios-app-development" lassName={activeMenu === "/ios-app-development" ? "active-menu" : ""}>iOS App Development</Link>
                            </li>
                        </ul>
                    </li>
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>CMS Development</Link>   
                    </li> */}


                    {/* <ul className="sub-menu"> */}
                            <li>
                            <Link to="/cms-development" lassName={activeMenu === "/wordpress-development" ? "active-menu" : ""}>CMS Development</Link>
                            </li>
                            
                            <li>
                                <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>eCommerce Development</Link>
                            </li>
                        {/* </ul> */}
                    <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/front-end" lassName={activeMenu === "/front-end" ? "active-menu" : ""}>Frontend Development</Link>   
                        <ul className="sub-menu">
                           {/* <li>
							   <Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UX/UI Design Development</Link>
							</li> */}
                            <li>
                            <Link to="/reactJs-development" className={activeMenu === "/reactJs-development" ? "active-menu" : ""}>ReactJs Development</Link>
                            </li>
                            <li>
                                <Link to="/angularJs-development" className={activeMenu === "/angularJs-development" ? "active-menu" : ""}>AngularJs Development</Link>
                            </li>
                            <li>
                                <Link to="/javaScripts-development" className={activeMenu === "/javaScripts-development" ? "active-menu" : ""}>JavaScripts Development</Link>
                            </li>
                            <li>
                                <Link to="/vueJs-development" lassName={activeMenu === "/vueJs-development" ? "active-menu" : ""}>VueJS Development</Link>
                            </li>
                            <li>
                                <Link to="/nodeJs-development" lassName={activeMenu === "/nodeJs-development" ? "active-menu" : ""}>NodeJs Development</Link>
                            </li>
                        </ul>
                    </li>  
                </ul>
            </li>
            {/* <li className={parentMenu === 'solution' ? 'current-menu-item' : ''}>
                <Link to="/solution" className={activeMenu === "/solution" ? "active-menu" : ""}>Solution</Link>
            </li> */}
            <li className={parentMenu === 'solution' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/solution" className={activeMenu === "/solution" ? "active-menu" : ""}>Solution</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="fintech-app-solution" className={activeMenu === "/fintech-app-solution" ? "active-menu" : ""}>Fintech-App Solution</Link>
                    </li>
                    <li>
                        <Link to="ecommerce-solutions" className={activeMenu === "/ecommerce-solutions" ? "active-menu" : ""}>E-Commerce App Solution</Link>
                    </li>
                    <li>
                        <Link to="business-app-solution" className={activeMenu === "/business-app-solution" ? "active-menu" : ""}>Business App Solutions</Link>
                    </li>
                    <li>
                        <Link to="fitness-wellness-app-solutions" className={activeMenu === "/fitness-wellness-app-solutions" ? "active-menu" : ""}>Fitness App Solutions</Link>
                    </li>
                    <li>
                        <Link to="travel-app-solutions" className={activeMenu === "/travel-app-solutions" ? "active-menu" : ""}>Travel App Solutions</Link>
                    </li>
                    <li>
                        <Link to="real-estate-app-solution" className={activeMenu === "/real-estate-app-solution" ? "active-menu" : ""}>Real Estate App Solutions</Link>
                    </li>
                    <li>
                        <Link to="healthcare-app-solution" className={activeMenu === "/healthcare-app-solution" ? "active-menu" : ""}>Healthcare App Solutions</Link>
                    </li>
                    <li>
                        <Link to="social-media-app" className={activeMenu === "/social-media-app" ? "active-menu" : ""}>Social Media App Solutions</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'hire-dedicated-developers' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/hire-dedicated-developers" className={activeMenu === "/hire-dedicated-developers" ? "active-menu" : ""}>Hire Developers</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="hire-flutter-developers" className={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>Hire Flutter Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-full-stack-developers" className={activeMenu === "/hire-full-stack-developers" ? "active-menu" : ""}>Hire Full Stack Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-mean-stack-developers" className={activeMenu === "/hire-mean-stack-developers" ? "active-menu" : ""}>Hire Mean Stack Developers</Link>
                    </li>

                    <li>
                        <Link to="hire-php-developers" className={activeMenu === "/hire-php-developers" ? "active-menu" : ""}>Hire PHP Developers</Link>
                    </li>

                    <li>
                        <Link to="hire-react-native-developers" className={activeMenu === "/hire-react-native-developers" ? "active-menu" : ""}>Hire React Native Developers</Link>
                    </li>
                    
                    <li>
                        <Link to="hire-nodejs-developers" className={activeMenu === "/hire-nodejs-developers" ? "active-menu" : ""}>Hire NodeJS Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-reactjs-developers" className={activeMenu === "/hire-reactjs-developers" ? "active-menu" : ""}>Hire ReactJs Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-angular-developer" className={activeMenu === "/hire-angular-developer" ? "active-menu" : ""}>Hire AngularJs Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-vuejs-developer" className={activeMenu === "/hire-vuejs-developer" ? "active-menu" : ""}>Hire Vue Js Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-android-app-developer" className={activeMenu === "/hire-android-app-developer" ? "active-menu" : ""}>Hire Android App Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-python-developers" className={activeMenu === "/hire-python-developers" ? "active-menu" : ""}>Hire Python Developers</Link>
                    </li>
                    <li>
                        <Link to="hire-ios-app-developer" className={activeMenu === "/hire-ios-app-developer" ? "active-menu" : ""}>Hire iOS App Developers</Link>
                    </li>
                    
                    {/* <li>
                        <Link to="hire-qa-developers" className={activeMenu === "/hire-qa-developers" ? "active-menu" : ""}>Hire QA Developers</Link>
                    </li> */}

                    <li>
                        <Link to="hire-ux-ui-developers" className={activeMenu === "/hire-ux-ui-developers" ? "active-menu" : ""}>Hire UX/UI Designers</Link>
                    </li>
                </ul>
            </li>

            <li className={parentMenu === 'Portfolio' ? 'current-menu-item' : ''}>
                <Link to="/Portfolio" className={activeMenu === "/Portfolio" ? "active-menu" : ""}>Portfolio</Link>
            </li>
            <Link className='getTouch' to="/contact" >Get In Touch</Link>  

        </React.Fragment>
    );
}
export default MenuItems;