import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg81 from '../../assets/img/service/style2/main-img/91.png';
import serviceImg82 from '../../assets/img/service/style2/main-img/92.png';
import serviceImg83 from '../../assets/img/service/style2/main-img/93.png';
import serviceImg84 from '../../assets/img/service/style2/main-img/94.png';
import serviceImg85 from '../../assets/img/service/style2/main-img/95.png';
import serviceImg86 from '../../assets/img/service/style2/main-img/96.png';
import serviceImg87 from '../../assets/img/service/style2/main-img/97.png';
import serviceImg88 from '../../assets/img/service/style2/main-img/91.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Real Estate Industry"
                    titleClass="title new-title pb-20"
                    title="Benefit from our Real estate mobile app development services."
                    descClass="desc"
                    description="Hold a top spot in the Real Estate Industry by letting us develop a custom app for you. Get ready to embrace the future with our real estate app made using cutting-edge tools and technology. Boosting your real estate business won’t be a dream anymore with our engaging application. The sky is the limit; with our custom real estate app, you can purchase, sell, and monitor properties, and do everything the right way."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg82}
                            Title="Real Estate Marketplace" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg81}
                            Title="Real Estate Web App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg83}
                            Title="Home Search Solution"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg84}
                            Title="Property Brokerage App"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg85}
                            Title="B2B & B2C Real Estate App" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg87}
                            Title="Lead Management Application"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg85}
                            Title="Real Estate Website Development"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg86}
                            Title="Online Property Booking App"
                        />
                    </div>
                   
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

