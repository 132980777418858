import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon90 from '../../assets/img/service/style2/main-img/90.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-80 md-pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="Why are we the best in the business when it comes to healthcare apps? "
                    descClass="desc text-dark"
                    description="We are backed up by professional healthcare app developers who creatively transform ideas into softwares, apps, and web solutions. You get a patient-focused and scalable app that is also flawless and provides a rich user experience. The app that we deliver you eases up the user’s processes of checking profiles, saving reports, and sharing them. "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={mainIcon2}
                            Title="Experienced Developers"
                            Text="We are well-versed in healthcare industry updates and technologically sound. That said, you can always stay unshaken about getting nothing but the best quality apps. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Custom & Intuitive Experiences"
                           Text="We respect the authenticity of each business. That’s why, we don’t fit every business in one box. Instead, we make personalized solutions as per your goals."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Procedure"
                            Text="Our methods are agile. We always come up with optimal practices to pace up your project and enhance the quality of the end result."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon90}
                            hoverIcon={mainIcon90}
                            Title="Round-The-Clock Support"
                           Text="Our 24/7 support ensures your emergencies are addressed and your system is upgraded promptly."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon6}
                            hoverIcon={mainIcon6}
                            Title="Cost-effectiveness"
                           Text="You may rest confident that you will receive affordable mobile app development services."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Quick Delivery"
                            Text="We are not only known for our quality but also for our punctuality. Rest assured! Your mobile app will be delivered on time."
                        />
                    </div>
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Get Best Healthcare App Solutions!"
                ctaTextClass="exp-text"
                ctaText="Build Your Own Advanced Digital Solutions to Enable a Fast-Paced Healthcare Ecosystem."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;