import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/style9/14.jpg';
import serviceImg13 from '../../assets/img/service/style9/13.jpg';
import serviceImg11 from '../../assets/img/service/style9/11.jpg';
import serviceImg12 from '../../assets/img/service/style9/12.jpg';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 ">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text style-bg"
                    subtitle="A full range of services to achieve your goals"
                    titleClass="title new-title"
                    title="Our judicious methodologies ensure converting websites and online marketing"
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Website Design & Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            Text="Top-notch, & effective website development that leverages modern trends & technologies.  
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Web Application Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            Text="Innovation and technology work together to develop user-friendly web apps.
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Mobile Application Development"
                            btnClass="services-btn3"
                            btnText="Read More"
                            Text="Highly-interactive, attractive, and future-proof solutions now retain on smaller screens!  
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-red"
                            serviceImg={serviceImg12}
                            Title="UI/UX And Graphics Designer"
                            btnClass="services-btn3"
                            btnText="Read More"
                            Text="Robust, customer-centric, & visually appealing UI/UX designs tailor-made to win hearts!
                            "
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;