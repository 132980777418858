import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/banking-app.png';
import aboutImg2 from '../../assets/img/about/finance-app.png';
import aboutImg3 from '../../assets/img/about/payment-app.png';
import aboutImg4 from '../../assets/img/about/wallet-app.png';



const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0, 
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
            <div className="bg18 style3 pt-80 pb-80 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text gold-color"
                        subtitle="Our Features"
                        titleClass="title pb-10 text-white"
                        title="Fintech Mobile Apps' Best Features
                        "
                        descClass="desc text-white"
                        description="We have got multiple Fintech projects under our belt. You are on your way to boosting sales and making a difference in the finance economy with us. Our specialized FinTech app development services guarantee integration, which makes things safer and swift. Our apps give the best user experience as we utilize high-quality tools and features in the development process. Our well-recognized team ensures you get a one-in-a-million solution as per your business goals. 
                        "
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg1}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-white pb-16"
                                            title="Banking App"
                                            descClass="desc2 text-white"
                                            description="Evolve with the ever-changing financial ambiance around. With us, you get a future-proof banking app, all set to appeal to users and scale your business with its attractive features. 
                                            "
                                        />
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Easy Managing Accounts</li>
                                                    <li className="text-white">View Transaction History</li>
                                                    <li className="text-white">Statement & Documents</li>
                                                    <li className="text-white">Check account balances</li>
                                                    
                                                </ul>
                                            </div>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Transfer Funds</li>
                                                    <li className="text-white">Products & Services</li>
                                                    <li className="text-white">Fixed Deposits & Interests</li>
                                                    <li className="text-white">Secure Login</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg2}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-white pb-16"
                                            title="Finance App"
                                            descClass="desc2 text-white"
                                            description="
                                            Want to plan and manage your finances more smartly? Our crew is all set to provide you with the best app using our expertise in the financial sector.
                                            "
                                        />
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Stock Market Report</li>
                                                    <li className="text-white">Online Stock Market</li>
                                                    <li className="text-white">Products and Services</li>
                                                    <li className="text-white">Create/Edit Portfolio</li> 
                                                </ul>
                                            </div>
                                            <div className='col-lg-6 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Invoice Management</li>
                                                    <li className="text-white">Reports and Analytics</li>
                                                    <li className="text-white">Advanced Customize Setting</li>
                                                    <li className="text-white">News & Market Dashboard</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg3}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-white pb-16"
                                            title="Payment Gateway"
                                            descClass="desc2 text-white"
                                            description="Our payment system gives you dual benefits. One: You can conduct free transactions with our payment service. Two: You can rest assured about our downright security."
                                        />
                                         <div className='row'>
                                            <div className='col-lg-5 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Multiple Payment Options</li>
                                                    <li className="text-white">Encryption Standards</li>
                                                    <li className="text-white">Easy & Convenient to Use</li>
                                                    <li className="text-white">Multi Currency Processing</li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-7 col-md-6'>
                                                <ul className="check-square">
                                                    <li className="text-white">Fraud Detection or Management System</li>
                                                    <li className="text-white">Cost or fee of conducting transaction</li>
                                                    <li className="text-white">Choice of Language Selection</li>
                                                    <li className="text-white">Speed & Invoice Capabilities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg4}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-white pb-16"
                                            title="Wallet App"
                                            descClass="desc2 text-white"
                                            description="Benefit from our digital Fintech studio’s features. You get all-in-one banking solutions that are user-friendly and use state-of-the-art technologies. 
                                            "
                                        />
                                        <div className='row'>
                                            <div className='col-lg-5 col-md-5'>
                                                <ul className="check-square">
                                                    <li className="text-white">Create a Virtual Card</li>
                                                    <li className="text-white">Send & Receive Money</li>
                                                    <li className="text-white">Tokenization</li>
                                                    <li className="text-white">QR Code Scan</li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-7 col-md-7'>
                                                <ul className="check-square">
                                                    <li className="text-white">Pay your Bills Easily</li>
                                                    <li className="text-white">Real-time Balance Check</li>
                                                    <li className="text-white">Transfer Money into Bank Account</li>
                                                    <li className="text-white">Discount Offers, Promos & Loyalty Programs</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider