import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const OurServices = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-process style4 gray-bg pt-80 pb-80">
           
            <div className="container relative">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-40"
                        subtitleClass="sub-text gold-color"
                        subtitle="We are working with"
                        titleClass="title pb-10"
                        title="Our Cost-effective React Native Web Development Services"
                        descClass="desc mb-25"
                        description="If you want to build a native-like app from scratch or use the elements of the React Native framework in your existing app, look no further. Hire us and we will let you experience a highly rewarding development process and give you a cost-effective and tailor-made solution to scale your business
                        "
                         effectClass="heading-line"
                />
                <div className="row align-items-center">

                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="React Native Ideation and Consulting"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="App development using React Native"
                                 />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="React Native Migration"
                                 />
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-6 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="React Native Support"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="5."
                                    Title="React Native Maintenance"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="6."
                                    Title="React Native MVP Development"
                                 />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-xs-12">
                        <div className="row">
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="7."
                                    Title="Migration to PHP"
                                />
                            </div>
                            <div className="col-md-11 mb-40">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="8."
                                    Title="Support & Maintenance"
                                />
                            </div>
                            
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default OurServices;