import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/40.png';
import imgMain41 from '../../assets/img/service/style2/main-img/41.png';
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain43 from '../../assets/img/service/style2/main-img/43.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import techImg34 from '../../assets/img/technology/style2/34.svg';
import techImg35 from '../../assets/img/technology/style2/35.svg';
import techImg36 from '../../assets/img/technology/style2/36.svg';
import techImg37 from '../../assets/img/technology/style2/37.svg';
import techImg38 from '../../assets/img/technology/style2/38.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const HTML5CSS3 = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="Hire best-in-class UI/UX Designers for your enterprise

                                "
                                descClass="desc pb-5"
                                description="It’s one thing to have a brilliant solution that values your customers and it’s another thing to present it the right way. Come up with the best idea and leave us for delivering a catchy, interactive, and rewarding website."
                                secondDescClass="desc pb-16"
                                secondDescription="You will get years of expertise to present you with a trending design that is highly immersive. Our UI/UX service is the most reliable to give you a custom website that profits you and awe-inspires your clients with its stunning interface. 

                                "
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-services-area-start */}
            <div className="rs-technology pt-30 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg34} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg35} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg36} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg37} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg38} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg9} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>   
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg3} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>                                  
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Next-Gen UX/UI Design Beside Professional Excellence

                        "
                        descClass="desc"
                        description="Your sweet ideas get a sweeter medium to reach out to the audience that matters to you the most. With our UI/UX services, your website is an easy-to-use, interactive, and valuable straw that stirs the drink.
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20">
                        <div className="row">
                            <div className="col-lg-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain40}
                                    HoverImg={imgMain40}
                                    Title="Web Interface Design"
                                    Text="Looking for a first impression like never before? You can impress end-users with a dedicated team of UI/UX designers, well-known for their revolutionary web designs. You get a team of experts who crafts an eye-pleasing website interface with intense research and a user-centric approach. 

                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain41}
                                    HoverImg={imgMain41}
                                    Title="Responsive Design Development"
                                    Text="We enable innovative ideas to dovetail creativity and flexibility. With our Responsive Design Development, you get the same jaw-dropping interface across a range of platforms. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="Wireframes & Prototypes"
                                    Text="We use Prototypes and Wireframes to test the design style 2  and framework. Then we go about crafting that attractive design that we promise to deliver. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Design Layout To HTML"
                                    Text="We give ears to your design suggestions. If you come up with a design that matches your brand value, we are on board to transform it into a hassle-free HTML document."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="Custom Logo Design & Branding"
                                    Text="We adorn your brand’s value with a unique and adaptable logo. We consider branding the bedrock of any successful endeavor.  We deliberately make a great impact on your potential customer with our branding services. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="With UI/UX Design Services, You Can Bridge The Gap Between Real and Digital."
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}         

            {/* rs-services-area-start */}
            <Technologies />
            {/* rs-services-area-start */}           

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text gold-color"
                            subtitle="Process"
                            titleClass="title title"
                            title="Looking for a Best UI/UX Design Service? Our Craftsmen are all set. 
                            "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="
                            The design we create gives your website the sheer power to boost conversion. You are at the right place if you are looking for a UI/UX design service that enriches the user experience of your site. 
                            "  
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Pixel-by-pixel, img-by-img"
                                Text="Your website feels and looks great as our industrious techies work dedicatedly. We analyze the future design time. Get your ideas designed by designers with a Midas touch! 
                                "
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="User-Centered Design" 
                                Text="What looks good and what suits you is what converts well. Keeping your end users on top, we skillfully blend design with usability and functionality. " 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Future-Centric Designs" 
                                Text="With our design assistance, your website is a beauty with a brain. Our serious designers create a logical and technical art that’s bound to impress and convert like crazy. 
                                " 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire our UI/UX Designers and witness a huge transformation"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our UI/UX masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default HTML5CSS3;