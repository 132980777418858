import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hiring Metaqualt's Mean Stack Developers is your most important asset?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Transparency
                            "
                           
                            Text="We value our customers. As a result, we involve you in our app development process from start to end. We give you a clear idea of what we are up to. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Experienced Developers"
                            
                            Text="Enjoy operating your first application developed by our exceptionally skilled Mean Stack developers. Count on our experience for fast delivery and robust solution.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Free Quotes"
                           
                            Text="Looking for web development? You have clicked the right link and landed on the right page. Apart from competitive rates, we offer free quotes. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Project Management"
                            
                           Text="Pat your back! You are dealing with an apt project management team. From ideation to maintenance, your app undergoes the right methodologies to become a star. "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Progress Reports"
                           
                            Text="Being a customer-centric company, you are informed from start to finish about the progress of your app. At any point, feel free to track down where we are. 
                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Future Proof Websites"
                           Text="Your Websites become future-proof, as we build them that way. We study your niche market and craft a modernized solution that is ready to embrace future challenges. 
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;