import React, { Component } from 'react';


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const Faq = () => {

    return (
        <main>
            {/* <!-- choose-area-start --> */}
            <div className="rs-faq choose-area pt-130 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="faq-wrapper pr-20">
                                <div className="section-title mb-45">
                                    <span className="b-sm-left-1"></span>
                                    <span className="b-sm-left-2"></span>
                                    <span className="sub-t-left">FAQ’s</span>
                                    <h1>Frequently Ask <br /> Questions</h1>
                                </div>
                                <div className="faq-box faq-2-box">
                                    <Accordion className="accodion-style1" preExpanded={'0'}>
                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Best It Solutions Provider Agency
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Professional & Experienced Team Member
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Our Main Goal How To Success In IT Business
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    We Start Our Journey Since 1990
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="choose-wrapper">
                                <div className="choose-text">
                                    <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment so blinded by desire that they cannot foresee the pain and trouble that are bound to ensue and equal blame belongs to those who fail in their duty through weakness of will which is the same as saying through shrinking from toil and pain cases are perfectly</p>
                                </div>
                                <div className="faq-box faq-2-box">
                                    <Accordion className="accodion-style1" preExpanded={'0'}>
                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Best It Solutions Provider Agency
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Professional & Experienced Team Member
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Our Main Goal How To Success In IT Business
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    We Start Our Journey Since 1990
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- choose-area-end --> */}

           

            {/* <!-- about-area-start --> */}
            <div className="rs-faq about-area pt-130 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="about-2-img">
                                <img src="assets/img/about/02.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-10">
                            <div className="faq-wrapper">
                                <div className="section-title mb-45">
                                    <span className="b-sm-left-1"></span>
                                    <span className="b-sm-left-2"></span>
                                    <span className="sub-t-left">FAQ’s</span>
                                    <h1>Frequently Ask <br /> Questions</h1>
                                    <p>Sedut perspiciatis unde omnis iste natus error sitlupttem accusantium doloremque laudantium totam remap eriaeaque ipsa quae ab illo inventore veritatis </p>
                                </div>
                                <div className="faq-box">
                                    <Accordion className="accodion-style1" preExpanded={'0'}>
                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Best It Solutions Provider Agency
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Professional & Experienced Team Member
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Our Main Goal How To Success In IT Business
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    We Start Our Journey Since 1990
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium   mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi artecto beatae dicta sunt explicabo. Nemo enim ipsam voluptatem quia  voluptas  aspernatur aut odit aut fugit</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- about-area-end --> */}
        </main>
    );
}

export default Faq;