import React from 'react';
import { Link } from 'react-router-dom';

const SingleServiceFour = (props) => {
	return(
        <div className="services-item">
            <div className="services-wrap">
                <div className="services-icon">
                    <Link to={props.solutionURL}>
                        <img 
                            src={props.serviceIcon} 
                            alt={props.Title}
                        />
                    </Link>
                </div>
                <div className="services-text">
                    <Link to={props.solutionURL}>
                        <h3 className="title">{props.Title}</h3>
                    </Link>
                </div>
            </div>
        </div>
	)
}

export default SingleServiceFour