import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg81 from '../../assets/img/service/11.png';
import serviceImg82 from '../../assets/img/service/12.png';
import serviceImg83 from '../../assets/img/service/13.png';
import serviceImg84 from '../../assets/img/service/14.png';
import serviceImg85 from '../../assets/img/service/15.png';
import serviceImg86 from '../../assets/img/service/16.png';
import serviceImg87 from '../../assets/img/service/17.png';
import serviceImg88 from '../../assets/img/service/18.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-130 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Medical & Healthcare Industry"
                    titleClass="title new-title pb-20"
                    title="Our services feature the best Medical & Healthcare Digital Solutions.
                    "
                    descClass="desc"
                    description="Nod to our healthcare services and watch your healthcare business grow immense profits and ROI. Our creativity and expertise in the latest technology help boost patient treatment and diagnosis. Your business, how small or large it may be, can benefit from our innovative solutions. Opt for us and ease up the medical processes like never before."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg81}
                            Title="Healthcare Mobile App" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg82}
                            Title="IoT Healthcare Software"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg83}
                            Title="Hospital Management"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg84}
                            Title="Medical Appointment"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg85}
                            Title="HIPPA, BAA, FDA Compliant Solutions"
                        />
                    </div>
                    
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg86}
                            Title="Medical software development" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg87}
                            Title="mHealth App Development"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg88}
                            Title="Telehealth Solutions for Hospitals & Clinics"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

