import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

import aboutPart1 from '../../assets/img/about/abut-1.png';


import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about rs-rain-animate pt-150 pb-80 md-pt-60">
            <div className="container">
                <div className="row pb-0">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <img className="main" src={aboutPart1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 pl-20 md-pt-40 sm-pt-40">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title2 mb-30"
                            subtitleClass="sub-text style-bg"
                            subtitle="About Us"
                            titleClass="title pb-25"
                            title='We help you Build, Manage and Transform your online Empire!'
                            descClass="desc pb-16"
                            description="Tired of managing every nook and cranny of your business on your own - from app development to web design? Are you sick of endless service providers who are a big no-no in terms of customer satisfaction? "
                            secondDescClass="desc pb-16"
                            secondDescription="
                            The dedicated crew at MetaQualt has all it takes from web and app development to maintenance. The experienced team of techno-brainiacs at MetaQualt 
                            comes up with a personalized service for every client. Automate your business, engage with your customers, and transform your digital domain with us!"  
                        />
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
                {/* counter area start */}
                {/* <CounterHomeFive /> */}
                {/* counter area end */}
                
            </div>
        </div>
        
    );
}

export default About;