import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.png';
import imgMain27 from '../../assets/img/service/style2/main-img/27.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';
import imgMain29 from '../../assets/img/service/style2/main-img/29.png';
import imgMain30 from '../../assets/img/service/style2/main-img/30.png';

// Service Image Icons

import serviceIcon14 from '../../assets/img/service/style2/main-img/34.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/35.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/36.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

// Service Icons
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const NodeJs = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-80">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20 md-pl-5">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Next-Gen Node.js Development Services"
                                titleClass="title pb-25"
                                title="You can always expect a real-time, scalable, and rapid Node.JS app from the best-in-class app development team."
                                descClass="desc pb-5"
                                description="Leverage the power of the most popular Javascript frameworks - Node.js to develop a feature-rich and scalable app. Avail yourself of the dual benefits of Node.j - its simple working environment and access to a variety of platforms! 

                                "
                                secondDescClass="desc pb-16"
                                secondDescription="Being a user-centric company, we make the most of our Node.js development expertise to create a product, including exciting features that suit your needs. We promise to give you a solution that users will love interacting with. Get ahead of the competition with us by surprising your end users with a gripping, real-time, and immersive app made using Node.js."
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}
           
           {/* rs-servicess-area-start */}
           <div className="rs-process style6 pt-50 pb-80 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Let the graph of your business growth rise rapidly with our Node.js development services."
                        descClass="desc"
                        description="Your search for a cutting-edge web application ends as you choose our Node.js team. Use our wall-to-wall knowledge of how Node.JS components work to create streaming applications, real-time web apps, chat programs, social media applications, messaging apps, and much more. Node.js makes things happen in an open-source environment."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain29}
                                    HoverImg={imgMain29}
                                    Title="Node JS Web Development"
                                    Text="By choosing us for the Node.js development, you raise your potential to excite users and stun your rivals with a highly customized and effective website. We do it using the latest tools and technologies we are well versed in. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Node JS Dynamic UI/UX"
                                    Text="Reach your hand out to us to witness the design of an interface go hand in hand with its user-friendliness for attracting tons of visitors. We are proud to be the efficient designers of Node.js Dynamic UI/UX."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                     MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="Node JS Real-Time Apps Solution"
                                    Text="Node.js expertly eases the development of real-time apps. We at MetaQualt, are hotshots when it comes to developing Node.js-based apps for web and mobile. Rely on us for fast, secure, and scalable apps."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain27}
                                    HoverImg={imgMain27}
                                    Title="Custom Node JS Development Solutions"
                                    Text="Not the size but the needs and goals of your business matter to us. Having a lot of successful Node.js projects under our belt, we bring smiles to clients by developing a solution tailored to their needs. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="NodeJS Migration Solutions"
                                    Text="Go with the flow! You can upgrade your existing website to Node.js and embrace its unique features. We are experts in the seamless migration of existing websites to Node.js or migrating a Node.js app to the newest version. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Delivering Next-Generation Node.js Services To Meet Your Business Objectives"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-60"
                            subtitleClass="sub-text gold-color"
                            subtitle="We Build Fast & Reliable Web Applications"
                            titleClass="title title2"
                            title="How do we work and turn your dreams into reality?"
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="We let you dig into our app development process. Here’s how we follow a strategic workflow, which is the success behind our company’s brilliant reputation."
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Send Us Your Project Requirements"
                                Text="We will go through your ideas and requirements sincerely. Then we will cherry-pick the best developer for your project to give you 100% customer satisfaction."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Terms & Timeframes For Engagement" 
                                Text="Based on the consultation provided by analysts, you are the one to choose the project timelines. We also offer milestone options. We use agile strategies to meet project deadlines." 
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon24}
                                Title="Secure Payment Methods" 
                                Text="We not only make secured apps, but we also make our client’s payments secure. You can opt for any of the multiple payment methods that are highly confidential. You get a cost-effective service. If you instantly pay us, we'll start working on your project.
                                " 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated Node.JS Developers and fulfill your dreams"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default NodeJs;