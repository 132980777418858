import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/healthcare-about.png';



const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0, 
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
             <div className="bg18 style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Our patient-friendly Medical & Healthcare App Solutions"
                                    descClass="desc text-white"
                                    description="We are crafters of Healthcare apps par excellence. Your companion in developing interesting health apps has worked on a multitude of projects, maintaining the output’s quality up to this date. For you as well, we are obliged to give a magic wand, that is, our app, that increases sales. Our app has been a boon to medical practitioners, device manufacturers, care providers, etc. 
                                    "
                                />
                                </div>
                                <div className='col-lg-5 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Diagnosis</li>
                                        <li className="text-white">Doctor-On-Demand</li>
                                        <li className="text-white">Health Monitoring</li>
                                        <li className="text-white">Scheduling & Reminders</li>
                                        <li className="text-white">Real-Time Chats</li>
                                        
                                    </ul>
                                </div>
                                <div className='col-lg-7 col-md-6'>
                                    <ul className="check-square">
                                        <li className="text-white">Patient Health Education</li>
                                        <li className="text-white">Cloud-Enabled</li>
                                        <li className="text-white">Patient Monitoring</li>
                                        <li className="text-white">Clinical Assistance</li>
                                        <li className="text-white">Healthcare Reference & Database</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider