import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg11 from '../../assets/img/project/11.jpg';
import projectImg12 from '../../assets/img/project/12.jpg';
import projectImg13 from '../../assets/img/project/13.jpg';
import projectImg14 from '../../assets/img/project/14.jpg';

import projectImg7 from '../../assets/img/bg/case-study.jpg';


const Technologies = () => {

    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 3,
                nav: true,
            },
            1366: {
                items: 3,
                nav: true,
            },
            1600: {
                items: 4,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0">
                <div className="">
                    <div className="row margin-0 align-items-center">

                        <div className="col-lg-4 padding-0 md-mb-50">
                            <div className="case-study" style={{ backgroundImage: `url(${projectImg7})` }}>
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    subtitleClass="sub-text white-color"
                                    subtitle=""
                                    titleClass="title testi-title white-color pb-20"
                                    title="eCommerce Websites Aid Online Businesses"
                                    descClass="desc-big"
                                    description="Transform your dreams into an eye-pleasing reality with our eCommerce services and products. With leading eCommerce providers like Magento, WooCommerce, and Shopify, we come up with an exemplary solution for your business."
                                />
                            </div>
                        </div>

                        <div className="col-lg-8 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg12} 
                                            Title="Magento"
                                            Text="Our success in building a great Magento store is in no small measure the outcome of our expertise in Magento development services. Is Magento store on your mind? Speak to us and anticipate a rewarding result. 
                                            "
                                           
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg11} 
                                            Title="Woocommerce"
                                            Text="Get the best plugin when it comes to eCommerce solutions. Manage your WordPress store with highly customizable and result-oriented WooCommerce." 
                                           
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg13} 
                                            Title="Shopify" 
                                            Text="Avail yourself of Shopify’s superb themes and features. Why not set up a Shopify store from the best web developers? Contact us and start breathing success with your stellar Shopify store. 
                                            " 
                                             
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg14} 
                                            Title="Custom Development"
                                            Text="
                                            Give yourself the sheer freedom of customizing your eCommerce store the way you want. Hire our developers to make it happen. 
                                            " 
                                           
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                        

                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;