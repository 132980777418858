import React, { Component, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import techImg1 from '../../../assets/img/technology/style2/1.svg';
import techImg2 from '../../../assets/img/technology/style2/2.svg';
import techImg3 from '../../../assets/img/technology/style2/3.svg';
import techImg4 from '../../../assets/img/technology/style2/4.svg';
import techImg5 from '../../../assets/img/technology/style2/5.svg';
import techImg7 from '../../../assets/img/technology/style2/7.svg';
import techImg10 from '../../../assets/img/technology/style2/10.svg';
import techImg13 from '../../../assets/img/technology/style2/13.svg';
import techImg17 from '../../../assets/img/technology/style2/17.svg';
import techImg18 from '../../../assets/img/technology/style2/18.svg';
import techImg19 from '../../../assets/img/technology/style2/19.svg';
import techImg20 from '../../../assets/img/technology/style2/20.svg';
import techImg21 from '../../../assets/img/technology/style2/21.svg';
import techImg22 from '../../../assets/img/technology/style2/22.svg';
import techImg23 from '../../../assets/img/technology/style2/23.svg';
import techImg24 from '../../../assets/img/technology/style2/24.svg';
import techImg25 from '../../../assets/img/technology/style2/25.svg';
import techImg28 from '../../../assets/img/technology/style2/28.svg';
import techImg34 from '../../../assets/img/technology/style2/34.svg';
import techImg35 from '../../../assets/img/technology/style2/35.svg';
import techImg36 from '../../../assets/img/technology/style2/36.svg';
import techImg37 from '../../../assets/img/technology/style2/37.svg';
import techImg38 from '../../../assets/img/technology/style2/38.svg';
import techImg39 from '../../../assets/img/technology/style2/39.svg';
import techImg40 from '../../../assets/img/technology/style2/40.png';
import techImg41 from '../../../assets/img/technology/style2/41.png';



const PortfolioTabTwo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    let tab1 = "Front-End / Web Development",
        tab2 = "Mobile App Development",
        tab3 = "UI/UX Design",
        tab4 = "CMS & ECommerce Service"
       
    const tabStyle = 'nav nav-tabs';

    return (
        <div className="tab-area">
            <Tabs>
                <div className="row">
                    <div className="col-lg-12">
                        <TabList className={tabStyle}>
                            <Tab>
                                <h3>{tab1}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab2}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab3}</h3>
                            </Tab>
                            <Tab>
                                <h3>{tab4}</h3>
                            </Tab>
                            
                        </TabList>
                    </div>
                    <div className="col-lg-12 pt-40 pb-30 md-mb-30">
                        <div className="tab-content">
                            <TabPanel>
                                <div className="image-wrap">
                                  <div class="react-tabs text-center">
                                        <h3 class="poppins-bold">Front-End / Web Development Services</h3>
                                        <p>Do you want to share your needs with someone who knows every bit of web development? How about befriending a team of skilled front-end developers who dig deep to bring a web design that stands out from a plethora of websites? We develop compelling industry-leading websites using the latest technologies.
</p>
                                        
                                        <div className="rs-technology">
                                            <div className="row">

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                      
                                                            <div className="logo-img">
                                                                <img src={techImg1} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg2} alt="" />
                                                            </div>
                                                    
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg3} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                     
                                                            <div className="logo-img">
                                                                <img src={techImg4} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg5} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg7} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg10} alt="" />
                                                            </div>
                                                      
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                        
                                                            <div className="logo-img">
                                                                <img src={techImg13} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg19} alt="" />
                                                            </div>
                                                   
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                    
                                                            <div className="logo-img">
                                                                <img src={techImg20} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg21} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="technology-item">
                                                       
                                                            <div className="logo-img">
                                                                <img src={techImg28} alt="" />
                                                            </div>
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 text-center">
                                                    <div class="btn-part mt-10 mb-10">
                                                        <a class="readon buy-now get-in" href="./web-development">Read More</a>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <h3 class="poppins-bold">Mobile App Development Services</h3>
                                            <p>Good gracious! Developing a mobile app with us will give you a unique mash-up of gripping design and a satisfying user experience. Indeed a dual benefit for any business, small or big, to attract 
                                                and engage numerous customers. Our dynamic talents are ready to serve you with iOS app Development, Android App Development, or Cross-Platform App Development.</p>
                                            
                                            <div className="rs-technology">
                                                <div className="row">
                                                
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg3} alt="" />
                                                                </div>
                                                         
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg17} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                         
                                                                <div className="logo-img">
                                                                    <img src={techImg18} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg13} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg10} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg1} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                            
                                                                <div className="logo-img">
                                                                    <img src={techImg4} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg5} alt="" />
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                
                                                    <div className="col-lg-12 text-center">
                                                    <div class="btn-part mt-10 mb-10"><a class="readon buy-now get-in" href="./mobile-app-development">Read More</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                        <h3 class="poppins-bold">UI/UX Design Services</h3>
                                        <p>AWe understand your brand to the fullest before we begin our design business. Our crew delicately crafts each design - layout, color, composition, interface, and such, considering the needs of end users. We focus on delivering the most appealing and user-friendly designs. That’s what we have been effectively doing for over a decade - unleashing our artistry for designing web and mobile apps from prototyping to pixel-perfect UI design.</p>
                                           
                                            <div className="rs-technology">
                                                <div className="row">
                                                
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                        
                                                                <div className="logo-img">
                                                                    <img src={techImg34} alt="" />
                                                                </div>
                                                       
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg35} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg36} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg37} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg38} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg39} alt="" />
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-lg-12 text-center">
                                                    <div class="btn-part mt-10 mb-10"><a class="readon buy-now get-in" href="./ux-ui-design">Read More</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="image-wrap">
                                    <div class="react-tabs text-center">
                                            <h3 class="poppins-bold">CMS & ECommerce Service</h3>
                                            <p>With us, having a globally recognized digital identity is just around the corner. How? Your commercial enterprise, how big or small it is, will transcend the geographical constraints with our e-commerce development solutions. Our team features a wide range of innovative e-commerce development options.</p>
                                           
                                            <div className="rs-technology">
                                                <div className="row">
                                                
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg22} alt="" />
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg23} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                          
                                                                <div className="logo-img">
                                                                    <img src={techImg24} alt="" />
                                                                </div>
                                                           
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg25} alt="" />
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item pt-0">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg40} alt="" />
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="technology-item pt-0">
                                                           
                                                                <div className="logo-img">
                                                                    <img src={techImg41} alt="" />
                                                                </div>
                                                         
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div className="col-lg-12 text-center">
                                                    <div class="btn-part mt-10 mb-10"><a class="readon buy-now get-in" href="./eCommerce-development">Read More</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>

    );
}
export default PortfolioTabTwo;