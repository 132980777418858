import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
import mainIcon99 from '../../assets/img/service/style2/main-img/99.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon98 from '../../assets/img/service/style2/main-img/98.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Hire a self-motivated team of NodeJS programmers from Metaqualt?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Delivering complete solution"
                            Text="By opting for MetaQualt, you get an end-to-end NodeJS development service worth every dime."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIcon65}
                            hoverIcon={mainIcon65}
                            Title="Tech Expertise"
                            Text="We offer you javascript development expertise; we have certified java developers for hire."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Agile Methodology "
                           Text="Speed up your web development process with our techno-savvy crew that uses agile methodologies to catalyze your project."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIcon3}
                            hoverIcon={mainIcon3}
                            Title="Detailed Consultation"
                            Text="To come up with a customized solution we give ears to your business needs. We undergo a thorough consultation before we start your project. 
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon98}
                            hoverIcon={mainIcon98}
                            Title="Speedy Communication"
                            Text="Count on us for getting a prompt response. You can stay in touch with the modern communication tools such as Slack, Jira, Skype, and Google Hangout."
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIcon99}
                            hoverIcon={mainIcon99}
                            Title="Simple & Flexible Contract"
                           Text="Make the most of our flexibility options. You are in control! Scale the team at any point in the development process. 
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;