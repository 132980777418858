import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.png';
import imgMain37 from '../../assets/img/service/style2/main-img/37.png';
import imgMain33 from '../../assets/img/service/style2/main-img/33.png';
import imgMain10 from '../../assets/img/service/style2/main-img/10.png';
import imgMain39 from '../../assets/img/service/style2/main-img/39.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons

import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';


const eCommerceDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="Get a revolutionary eCommerce model that generates high revenues"
                                descClass="desc pb-5"
                                description="A terrific e-shopping magnet is what you want, and with our experience in the latest technologies, we develop one exclusively for you. You get a fully rigged tech daddy who dominates the new language of shopping - an e-commerce shopping store/app."
                                secondDescClass="desc pb-16"
                                secondDescription="With our e-commerce strategy, integration, migration, and support, what you get is a sheer profitable product designed to attract a swarm of customers. You are the captain of your website, and we give the best user experience possible. Contact us to own a booming platform, for both buyers and merchants, on a global level. 
                                "
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text gold-color"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="eCommerce Development Services from Start to Finish"
                        descClass="desc"
                        description="
                        Your eCommerce requirements meet the right developers at MetaQualt. We are passionate about developing varied e-commerce platforms recognized for their outstanding features and functions. We tap into the latest technologies to craft a solution that guarantees to give buyers an extraordinary shopping experience. 
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain10}
                                    HoverImg={imgMain10}
                                    Title="e-Commerce Website Design"
                                    Text="You witness a trusted, friendly, and mutually beneficial interaction between consumers and clients with our sleek website. Providing you with a first-class user experience is what we promise. Our growth depends on your website’s appeal and performance. We give our best shot to let your business ideas crystallize into a grand success via your smart website. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain33}
                                    HoverImg={imgMain33}
                                    Title="e-Commerce Apps"
                                    Text="How about handling things from the comfort of your pocket with the assistance of smart developers? Our clever developers have integrated features like cameras, GPS trackers, and microphones to keep track of your customer's actions. With us, your pragmatic app is sure to be the best companion in your e-growth.  
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain37}
                                    HoverImg={imgMain37}
                                    Title="End-to-End eCommerce Development"
                                    Text="A rich user experience is our motto while developing your e-commerce app or website. Your online store is a success as a result of our excellent code execution. Mark our words! You will be proud of your apps’ interaction with the end users. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="Plug-in & Module Development"
                                    Text="Get an optimal functioning e-Commerce store with a plugin and module development service. We develop custom plugins and modules to beef up your online market. Get Developer-built modules for your core system that are tailored to your requirements."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain39}
                                    HoverImg={imgMain39}
                                    Title="e-Commerce SEO Services"
                                    Text="Developing a trendy web platform is a big thing. Making it easily visible to potential clients is equally important. With us, your e-commerce brand gets the limelight it deserves. Our on-page optimization, link-building, and other SEO strategies are the surefire ways to get vivid exposure on Google, Bing, Yahoo, etc. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Leading e-Commerce Development Provides A Better Buying Experience"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why Choose Us?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Experience & Expertise"
                            Text="We help scale businesses of every size with stunning eCommerce apps and websites. 
                            Your niche matters to us, and we build a revolutionary platform to help you supersize your dreams. Our years of expertise come into play in developing a trend-setting eCommerce solution. 
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Dedicated E-commerce Process" 
                            Text="Team up with the industry leaders in eCommerce development. You concentrate on giving the best value to your clients and leave it to us to make the best eCommerce store. We make things happen with our experience in working with recent tools, frameworks, and our strategic approach." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon15}
                            Title="Full Life Cycle of e-Commerce" 
                            Text="Your optimum site is the result of our comprehensive and end-to-end eCommerce development services. From a discussion of ideas, designing, and deployment to delivery, we handle all that is “web” pretty effectively. Sit back and watch your brand grow!" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Competitive Rate Of Development" 
                            Text="You don’t have to wait for years to get that ROI anymore. Hire us! You are on your way to getting a high-value e-commerce solution for mobile and web at a highly competitive rate.                            " 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-60 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire exceptional eCommerce platform developers
                "
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, designing, deployment, and delivery to turn them into irresistible eCommerce apps. With our web app masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}
                       
            {/* working-process-area-start */}
            {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default eCommerceDevelopment;